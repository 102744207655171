// src/pages/AdminPortal.jsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import AdminHeader from '../components/admin/AdminHeader';
import PredictionAnalytics from '../components/admin/PredictionAnalytics';
import TeamAnalytics from '../components/admin/TeamAnalytics';
import PlayerAnalytics from '../components/admin/PlayerAnalytics';
import BetTypeAnalytics from '../components/admin/BetTypeAnalytics';

const AdminPortal = () => {
  const [activeTab, setActiveTab] = useState('predictions');

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'predictions':
        return <PredictionAnalytics />;
      case 'teams':
        return <TeamAnalytics />;
      case 'players':
        return <PlayerAnalytics />;
      case 'betTypes':
        return <BetTypeAnalytics />;
      default:
        return <PredictionAnalytics />;
    }
  };

  return (
    <motion.div
      className="min-h-screen bg-[#0B1120]"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <AdminHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {renderContent()}
      </div>
    </motion.div>
  );
};

export default AdminPortal;