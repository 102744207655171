import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { 
  ShieldCheckIcon, 
  BoltIcon, 
  TrophyIcon,
  EyeIcon,
  EyeSlashIcon,
  ChartBarIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [referral, setReferral] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { register } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.rewardful('ready', () => {
      if (window.Rewardful?.referral) {
        setReferral(window.Rewardful.referral);
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      window.gtag?.('event', 'registration_error', {
        'event_category': 'error',
        'event_label': 'password_mismatch'
      });
      return;
    }
    
    try {
      await register(formData.email, formData.password, formData.name, referral);

      try {
        const response = await fetch('https://services.leadconnectorhq.com/contacts/upsert', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${import.meta.env.VITE_GHL_API_KEY}`,
            'Content-Type': 'application/json',
            'Version': '2021-07-28'
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            locationId: "8lQAYS7QatKYV3ENYdl1",
            tags: ["sportsbetuser"],
            source: "Sports Bet App"
          })
        });

        if (!response.ok) {
          console.error('GHL API Error:', await response.text());
        }
      } catch (ghlError) {
        console.error('GHL API Error:', ghlError);
      }

      window.rewardful?.('convert', { 
        email: formData.email 
      });
      window.gtag?.('event', 'sign_up', {
        'event_category': 'engagement',
        'event_label': 'registration_success'
      });
      
      navigate('/');
    } catch (error) {
      window.gtag?.('event', 'registration_error', {
        'event_category': 'error',
        'event_label': 'registration_failure'
      });
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0B1120] py-12 px-4 sm:px-6 lg:px-8">
      {/* Background Patterns */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-4000"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_200px,rgba(120,119,198,0.3),transparent)]"></div>
        <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-[0.02]"></div>
      </div>

      <motion.div 
        className="container mx-auto px-4 relative z-10"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="max-w-4xl mx-auto">
          <motion.div 
            className="text-center mb-8"
            variants={itemVariants}
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 via-indigo-400 to-purple-500 bg-clip-text text-transparent">
              Start Your Free Membership Today!
            </h1>
            <p className="text-xl text-gray-300 mb-4">
              Get instant access to AI-powered sports predictions—no credit card required.
            </p>
            <motion.div 
              className="inline-block bg-green-500/20 text-green-400 px-6 py-2 rounded-full border border-green-500/30"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              🔥 Join 1800+ smart bettors using AI predictions!
            </motion.div>
          </motion.div>

          <motion.div 
            className="text-center mb-6"
            variants={itemVariants}
          >
            <Link 
              to="/login" 
              className="text-gray-400 hover:text-white transition-colors inline-flex items-center gap-1 hover:gap-2 duration-200"
            >
              Already have an account? <span className="font-semibold">Log in →</span>
            </Link>
          </motion.div>

          <div className="flex flex-col md:flex-row gap-8 items-start">
            <motion.div 
              className="w-full md:w-1/2 backdrop-blur-sm bg-white/[0.02] p-8 rounded-2xl border border-white/[0.05] shadow-2xl"
              variants={itemVariants}
            >
              <h2 className="text-2xl font-bold text-white mb-6">Create Your Free Account</h2>
              
              {error && (
                <motion.div 
                  className="bg-red-500/10 border border-red-500/50 text-red-400 rounded-xl p-4 mb-6"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <div className="flex items-center gap-2">
                    <XCircleIcon className="h-5 w-5" />
                    <span>{error}</span>
                  </div>
                </motion.div>
              )}

              <form onSubmit={handleSubmit} className="space-y-5">
                {referral && <input type="hidden" name="referral" value={referral} />}
                
                <motion.div variants={itemVariants}>
                  <label className="block text-gray-300 mb-2 font-medium">Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                    className="w-full bg-white/[0.02] rounded-xl p-3.5 text-white border border-white/[0.05] 
                      focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all placeholder-gray-500"
                    required
                  />
                </motion.div>

                <motion.div variants={itemVariants}>
                  <label className="block text-gray-300 mb-2 font-medium">Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    className="w-full bg-white/[0.02] rounded-xl p-3.5 text-white border border-white/[0.05] 
                      focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all placeholder-gray-500"
                    required
                  />
                </motion.div>

                <motion.div variants={itemVariants}>
                  <label className="block text-gray-300 mb-2 font-medium">Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Choose a password"
                      value={formData.password}
                      onChange={(e) => setFormData({...formData, password: e.target.value})}
                      className="w-full bg-white/[0.02] rounded-xl p-3.5 text-white border border-white/[0.05] 
                        focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all pr-10 placeholder-gray-500"
                      required
                    />
                    <motion.button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 
                        hover:text-gray-300 focus:outline-none"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="h-5 w-5" />
                      ) : (
                        <EyeIcon className="h-5 w-5" />
                      )}
                    </motion.button>
                  </div>
                </motion.div>

                <motion.div variants={itemVariants}>
                  <label className="block text-gray-300 mb-2 font-medium">Confirm Password</label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm your password"
                      value={formData.confirmPassword}
                      onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
                      className="w-full bg-white/[0.02] rounded-xl p-3.5 text-white border border-white/[0.05] 
                        focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all pr-10 placeholder-gray-500"
                      required
                    />
                    <motion.button
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 
                        hover:text-gray-300 focus:outline-none"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      {showConfirmPassword ? (
                        <EyeSlashIcon className="h-5 w-5" />
                      ) : (
                        <EyeIcon className="h-5 w-5" />
                      )}
                    </motion.button>
                  </div>
                </motion.div>

                {formData.password && formData.confirmPassword && (
                  <motion.div 
                    className={`text-sm ${
                      formData.password === formData.confirmPassword 
                        ? 'text-green-400' 
                        : 'text-red-400'
                    }`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    {formData.password === formData.confirmPassword 
                      ? '✓ Passwords match' 
                      : '✗ Passwords do not match'}
                  </motion.div>
                )}

                <motion.button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 text-white rounded-xl p-4 
                    font-semibold transition-all transform hover:scale-[1.02] active:scale-[0.98]
                    shadow-lg mt-6 disabled:opacity-50 disabled:cursor-not-allowed relative overflow-hidden group`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="relative z-10">
                    {isLoading ? 'Creating Account...' : 'Start Winning Today - Free!'}
                  </span>
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity" />
                </motion.button>
              </form>
            </motion.div>

            <motion.div 
              className="w-full md:w-1/2 text-white space-y-6"
              variants={itemVariants}
            >
              <motion.div 
                className="space-y-6"
                variants={containerVariants}
              >
                <motion.div 
                  className="flex items-start space-x-4 backdrop-blur-sm bg-white/[0.02] p-6 rounded-xl border border-white/[0.05]"
                  whileHover={{ scale: 1.02 }}
                  variants={itemVariants}
                >
                  <BoltIcon className="w-8 h-8 text-blue-400 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-2">Weekly Free Picks</h3>
                    <p className="text-gray-400">Get AI-powered predictions for every game, delivered straight to your account</p>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-start space-x-4 backdrop-blur-sm bg-white/[0.02] p-6 rounded-xl border border-white/[0.05]"
                  whileHover={{ scale: 1.02 }}
                  variants={itemVariants}
                >
                  <ChartBarIcon className="w-8 h-8 text-purple-400 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-2">Advanced Analytics</h3>
                    <p className="text-gray-400">Access detailed statistics and performance metrics to make informed decisions</p>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-start space-x-4 backdrop-blur-sm bg-white/[0.02] p-6 rounded-xl border border-white/[0.05]"
                  whileHover={{ scale: 1.02 }}
                  variants={itemVariants}
                >
                  <UserGroupIcon className="w-8 h-8 text-indigo-400 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-2">Community Insights</h3>
                    <p className="text-gray-400">Join a community of smart bettors and share strategies for success</p>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-start space-x-4 backdrop-blur-sm bg-white/[0.02] p-6 rounded-xl border border-white/[0.05]"
                  whileHover={{ scale: 1.02 }}
                  variants={itemVariants}
                >
                  <ShieldCheckIcon className="w-8 h-8 text-green-400 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-2">Secure & Private</h3>
                    <p className="text-gray-400">Your data is protected with enterprise-grade security and encryption</p>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Register;