import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const NBAPlayerGameItem = ({ gameStats }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const contentVariants = {
    hidden: { 
      height: 0,
      opacity: 0
    },
    visible: { 
      height: "auto",
      opacity: 1,
      transition: {
        height: {
          type: "spring",
          stiffness: 500,
          damping: 30
        },
        opacity: {
          duration: 0.2
        }
      }
    }
  };

  return (
    <motion.div 
      className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 rounded-xl border border-white/5"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover={{ scale: 1.01 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
      
      {/* Header - Always visible */}
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="relative w-full px-4 py-3 flex items-center hover:bg-gray-700/30 transition-colors duration-200"
        whileHover={{ x: 2 }}
      >
        <div className="flex items-center w-48">
          <motion.div 
            className="relative w-6 h-6 mr-2"
            whileHover={{ rotate: [0, -5, 5, 0] }}
            transition={{ duration: 0.5 }}
          >
            <img 
              src={gameStats.opponent.logo} 
              alt={gameStats.opponent.name}
              className="w-full h-full"
            />
            <motion.div 
              className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-4 h-[1px] bg-gradient-to-r from-transparent via-blue-500/50 to-transparent"
              animate={{ 
                opacity: [0.3, 1, 0.3],
                width: ["12px", "20px", "12px"]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            />
          </motion.div>
          <span className="text-white">vs {gameStats.opponent.name}</span>
        </div>
        
        <div className="flex-1 flex items-center">
          <motion.span 
            className="text-gray-300 mr-8"
            animate={{ 
              opacity: [0.8, 1, 0.8],
              scale: [1, 1.02, 1]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            {gameStats.score.team} - {gameStats.score.opponent}
          </motion.span>
          <span className="text-gray-500">
            {new Date(gameStats.gameDate).toLocaleDateString()}
          </span>
        </div>

        <motion.div 
          className="text-blue-400 ml-4"
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ type: "spring", stiffness: 200, damping: 20 }}
        >
          ▼
        </motion.div>
      </motion.button>

      {/* Collapsible Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            className="overflow-hidden"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <div className="relative p-4 space-y-3 border-t border-gray-700/50">
              {gameStats.players.map((player, index) => (
                <NBAPlayerStatsCard 
                  key={player.id}
                  player={player}
                  index={index}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const NBAPlayerStatsCard = ({ player, index }) => {
  const statVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        delay: index * 0.1,
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <motion.div 
      className="relative overflow-hidden bg-gray-800/50 p-4 rounded-lg border border-white/5"
      variants={statVariants}
      initial="hidden"
      animate="visible"
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
      <div className="relative">
        <div className="flex items-center mb-3">
          <motion.span 
            className="text-white font-medium"
            animate={{ 
              opacity: [0.8, 1, 0.8],
              scale: [1, 1.02, 1]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            {player.name}
          </motion.span>
          <motion.span 
            className="text-gray-400 text-sm ml-2"
            animate={{ 
              opacity: [0.6, 0.8, 0.6]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            #{player.number}
          </motion.span>
        </div>
        
        <div className="grid grid-cols-4 gap-3">
          <StatBox title="Points" value={player.points} />
          <StatBox title="Rebounds" value={player.rebounds?.total} />
          <StatBox title="Assists" value={player.assists} />
          <StatBox title="Blocks" value={player.blocks} />
        </div>
      </div>
    </motion.div>
  );
};

const StatBox = ({ title, value }) => (
  <motion.div 
    className="bg-gray-800/30 p-2 rounded-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300, damping: 15 }}
  >
    <div className="text-xs text-gray-400 mb-1">{title}</div>
    <motion.div 
      className="text-sm font-medium bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent"
      animate={{ 
        opacity: [0.8, 1, 0.8],
        scale: [1, 1.02, 1]
      }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      {value || 0}
    </motion.div>
  </motion.div>
);

export default NBAPlayerGameItem; 