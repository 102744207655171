import React, { useState } from 'react';
import GameStats from './GameStats';
import PlayerStats from './PlayerStats';
import NFLPredictions from './sports/NFL/NFLPredictions';
import NBAPredictions from './sports/NBA/NBAPredictions';
import NBAPlayerStats from './sports/NBA/NBAPlayerStats';
import { useSport } from '../../contexts/SportContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from '@react-spring/web';

const GameOverview = ({ game, predictions, onClose }) => {
  const [activeTab, setActiveTab] = useState('Team Stats');
  const [activeTeam, setActiveTeam] = useState('home');
  const { teams } = game;
  const { activeSport } = useSport();

  // Framer Motion variants
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const modalVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.8,
      y: 20
    },
    visible: { 
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: 20,
      transition: {
        duration: 0.2
      }
    }
  };

  const tabVariants = {
    inactive: { scale: 1 },
    active: { 
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 30
      }
    }
  };

  const handleTeamSelect = (team) => {
    setActiveTeam(team);
  };

  const renderPredictions = () => {
    if (activeSport === 'NBA') {
      return <NBAPredictions predictions={predictions} />;
    }
    return <NFLPredictions predictions={predictions} />;
  };

  return (
    <motion.div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-2 sm:p-4"
      variants={overlayVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.div 
        className="bg-[#1a1f2e]/90 backdrop-blur-xl rounded-2xl w-full max-w-3xl shadow-2xl flex flex-col max-h-[95vh] sm:max-h-[90vh] border border-white/10"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {/* Game Header */}
        <motion.div 
          className="p-4 sm:p-6 flex flex-col items-center bg-gradient-to-b from-[#141824] to-[#141824]/50 rounded-t-2xl flex-shrink-0 relative overflow-hidden"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          {/* Ambient lighting effects */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
            <div className="absolute -left-[100%] top-[50%] w-[300%] h-1/2 bg-gradient-to-br from-blue-500/[0.08] via-transparent to-transparent rotate-12 blur-3xl animate-aurora" />
          </div>

          <div className="relative flex flex-col sm:flex-row items-center justify-between w-full gap-4">
            {/* Home Team */}
            <motion.div 
              className="flex items-center space-x-4 sm:space-x-6"
              whileHover={{ x: 5 }}
              transition={{ type: "spring", stiffness: 300, damping: 15 }}
            >
              <div className="relative">
                <motion.div 
                  className="w-12 h-12 sm:w-16 sm:h-16 rounded-2xl bg-gradient-to-br from-gray-800/80 to-gray-900 p-2 sm:p-3 border border-white/[0.08]"
                  whileHover={{ rotate: [0, -5, 5, 0] }}
                  transition={{ duration: 0.5 }}
                >
                  <img src={teams.home.logo} alt={teams.home.name} className="w-full h-full" />
                </motion.div>
                <motion.div 
                  className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-8 sm:w-12 h-[1px] bg-gradient-to-r from-transparent via-blue-500/50 to-transparent"
                  animate={{ 
                    opacity: [0.3, 1, 0.3],
                    width: ["24px", "48px", "24px"]
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                />
              </div>
              <div>
                <motion.div 
                  className="text-base sm:text-xl font-bold text-white"
                  animate={{ 
                    opacity: [0.8, 1, 0.8],
                    scale: [1, 1.02, 1]
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  {teams.home.name}
                </motion.div>
                <div className="text-xs sm:text-sm text-gray-400 font-medium">{teams.home.code}</div>
              </div>
            </motion.div>

            {/* VS Section */}
            <motion.div 
              className="text-xl sm:text-2xl font-bold text-white/80 px-4 sm:px-6 py-2 sm:py-3 rounded-xl bg-white/5 border border-white/10"
              animate={{ 
                scale: [1, 1.05, 1],
                borderColor: ["rgba(255,255,255,0.1)", "rgba(255,255,255,0.2)", "rgba(255,255,255,0.1)"]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              VS
            </motion.div>

            {/* Away Team */}
            <motion.div 
              className="flex flex-row-reverse sm:flex-row items-center space-x-reverse sm:space-x-6 sm:space-x-reverse"
              whileHover={{ x: -5 }}
              transition={{ type: "spring", stiffness: 300, damping: 15 }}
            >
              <div className="relative order-1 sm:order-2">
                <motion.div 
                  className="w-12 h-12 sm:w-16 sm:h-16 rounded-2xl bg-gradient-to-br from-gray-800/80 to-gray-900 p-2 sm:p-3 border border-white/[0.08]"
                  whileHover={{ rotate: [0, 5, -5, 0] }}
                  transition={{ duration: 0.5 }}
                >
                  <img src={teams.away.logo} alt={teams.away.name} className="w-full h-full" />
                </motion.div>
                <motion.div 
                  className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-8 sm:w-12 h-[1px] bg-gradient-to-r from-transparent via-blue-500/50 to-transparent"
                  animate={{ 
                    opacity: [0.3, 1, 0.3],
                    width: ["24px", "48px", "24px"]
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                />
              </div>
              <div className="order-2 sm:order-1 text-right">
                <motion.div 
                  className="text-base sm:text-xl font-bold text-white"
                  animate={{ 
                    opacity: [0.8, 1, 0.8],
                    scale: [1, 1.02, 1]
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  {teams.away.name}
                </motion.div>
                <div className="text-xs sm:text-sm text-gray-400 font-medium">{teams.away.code}</div>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Overview Header */}
        <div className="flex items-center justify-between p-3 sm:p-4 border-b border-white/5 flex-shrink-0">
          <motion.h3 
            className="text-base sm:text-lg font-medium text-white"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
          >
            Game Overview
          </motion.h3>
          <motion.button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors duration-200"
            whileHover={{ scale: 1.1, rotate: 90 }}
            whileTap={{ scale: 0.9 }}
          >
            ✕
          </motion.button>
        </div>

        {/* Navigation Tabs */}
        <motion.div 
          className="flex flex-wrap sm:flex-nowrap gap-2 p-3 sm:p-4 flex-shrink-0"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          {['Team Stats', 'Players', 'Predictions'].map((tab) => (
            <motion.button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`flex-1 min-w-[120px] px-3 sm:px-4 py-2 rounded-xl relative overflow-hidden ${
                activeTab === tab
                  ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
              variants={tabVariants}
              animate={activeTab === tab ? "active" : "inactive"}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-blue-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="relative flex items-center justify-center space-x-2">
                <span>{tab === 'Team Stats' && '📊'}</span>
                <span>{tab === 'Players' && '👥'}</span>
                <span>{tab === 'Predictions' && '📍'}</span>
                <span className="font-medium text-sm sm:text-base">{tab}</span>
              </div>
            </motion.button>
          ))}
        </motion.div>

        {/* Scrollable Content Area */}
        <motion.div 
          className="flex-1 overflow-y-auto min-h-0 custom-scrollbar"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <div className="p-3 sm:p-4">
            {/* Team Toggle */}
            {(activeTab === 'Team Stats' || activeTab === 'Players') && (
              <motion.div 
                className="flex rounded-xl overflow-hidden border border-white/10 mb-4 p-1 bg-gradient-to-r from-gray-800/50 to-gray-900/50"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                <motion.button
                  onClick={() => handleTeamSelect('home')}
                  className={`flex-1 py-2 px-3 sm:px-4 rounded-lg flex items-center justify-center space-x-2 ${
                    activeTeam === 'home' 
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white' 
                      : 'bg-transparent text-gray-400 hover:text-white'
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <img 
                    src={teams.home.logo} 
                    alt={teams.home.name} 
                    className="w-5 h-5 sm:w-6 sm:h-6"
                  />
                  <span className="font-medium text-sm sm:text-base truncate">{teams.home.name}</span>
                </motion.button>
                <motion.button
                  onClick={() => handleTeamSelect('away')}
                  className={`flex-1 py-2 px-3 sm:px-4 rounded-lg flex items-center justify-center space-x-2 ${
                    activeTeam === 'away' 
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white' 
                      : 'bg-transparent text-gray-400 hover:text-white'
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <img 
                    src={teams.away.logo} 
                    alt={teams.away.name} 
                    className="w-5 h-5 sm:w-6 sm:h-6"
                  />
                  <span className="font-medium text-sm sm:text-base truncate">{teams.away.name}</span>
                </motion.button>
              </motion.div>
            )}

            {/* Tab Content */}
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
                className="space-y-4"
              >
                {activeTab === 'Team Stats' && (
                  <GameStats 
                    teamId={activeTeam === 'home' ? teams.home.id : teams.away.id}
                    sport={activeSport}
                    gameId={game.id}
                  />
                )}
                
                {activeTab === 'Players' && (
                  activeSport === 'NBA' ? (
                    <NBAPlayerStats 
                      teamId={activeTeam === 'home' ? teams.home.id : teams.away.id}
                    />
                  ) : (
                    <PlayerStats 
                      gameId={game.id}
                      teamId={activeTeam === 'home' ? teams.home.id : teams.away.id}
                      sport={activeSport}
                    />
                  )
                )}

                {activeTab === 'Predictions' && renderPredictions()}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default GameOverview;