import React, { useState } from 'react';
import { format } from 'date-fns';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const PredictionTable = ({ predictions, loading, betType }) => {
  const [expandedRows, setExpandedRows] = useState(new Set());

  const formatDate = (isoString) => {
    if (!isoString) return 'N/A';
    const date = new Date(isoString);
    return format(date, 'EEE, MMM d, yyyy h:mm a');
  };

  const calculateMetrics = (prediction) => {
    const metrics = {
      gameWinner: { correct: 0, total: 0, confidence: 0 },
      pointSpread: { correct: 0, total: 0, confidence: 0 },
      totalPoints: { correct: 0, total: 0, confidence: 0 },
      players: { correct: 0, total: 0, confidence: 0 }
    };

    // Game winner
    if (prediction.results?.gameWinner) {
      metrics.gameWinner.total++;
      if (prediction.results.gameWinner.correct) metrics.gameWinner.correct++;
      metrics.gameWinner.confidence = prediction.prediction?.gameWinner?.confidence || 0;
    }

    // Point spread
    if (prediction.results?.pointSpread) {
      metrics.pointSpread.total++;
      if (prediction.results.pointSpread.correct) metrics.pointSpread.correct++;
      metrics.pointSpread.confidence = prediction.prediction?.pointSpread?.confidence || 0;
    }

    // Total points
    if (prediction.results?.totalPoints) {
      metrics.totalPoints.total++;
      if (prediction.results.totalPoints.correct) metrics.totalPoints.correct++;
      metrics.totalPoints.confidence = prediction.prediction?.totalPoints?.confidence || 0;
    }

    // Player props
    if (prediction.results?.players) {
      Object.entries(prediction.results.players).forEach(([playerName, playerResults]) => {
        Object.entries(playerResults.correct || {}).forEach(([stat, isCorrect]) => {
          metrics.players.total++;
          if (isCorrect) metrics.players.correct++;
          const confidence = prediction.confidence?.players?.[stat] || 0;
          metrics.players.confidence += confidence;
        });
      });
      
      if (metrics.players.total > 0) {
        metrics.players.confidence /= metrics.players.total;
      }
    }

    return metrics;
  };

  const toggleRow = (id) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  if (loading) {
    return (
      <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/50">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-700 rounded w-3/4"></div>
          <div className="space-y-3">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-16 bg-gray-700 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800/50 rounded-xl overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-900/50">
            <tr>
              <th className="w-8 px-2 py-3"></th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Game Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Teams
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Overall
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider hidden lg:table-cell">
                Game Winner
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider hidden lg:table-cell">
                Point Spread
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider hidden lg:table-cell">
                Total Points
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider hidden lg:table-cell">
                Player Props
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {predictions
              .sort((a, b) => new Date(b.gameDate) - new Date(a.gameDate))
              .map((pred) => {
              const metrics = calculateMetrics(pred);
              const totalCorrect = Object.values(metrics).reduce((sum, m) => sum + m.correct, 0);
              const totalPreds = Object.values(metrics).reduce((sum, m) => sum + m.total, 0);
              const overallAccuracy = totalPreds > 0 ? (totalCorrect / totalPreds) * 100 : 0;
              const isExpanded = expandedRows.has(pred.id);

              return (
                <React.Fragment key={pred.id}>
                  <tr className={`hover:bg-gray-700/30 ${isExpanded ? 'bg-gray-700/20' : ''}`}>
                    <td className="px-2 py-4">
                      <button
                        onClick={() => toggleRow(pred.id)}
                        className="text-gray-400 hover:text-white"
                      >
                        {isExpanded ? (
                          <ChevronUpIcon className="h-5 w-5" />
                        ) : (
                          <ChevronDownIcon className="h-5 w-5" />
                        )}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {formatDate(pred.gameDate)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      <div className="flex flex-col">
                        <span>{pred.teams.away?.name || 'N/A'}</span>
                        <span>@</span>
                        <span>{pred.teams.home?.name || 'N/A'}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center">
                        <div className={`w-2 h-2 rounded-full mr-2 ${
                          overallAccuracy >= 70 ? 'bg-green-500' :
                          overallAccuracy >= 50 ? 'bg-yellow-500' :
                          'bg-red-500'
                        }`}></div>
                        <span className="text-gray-300">{overallAccuracy.toFixed(1)}%</span>
                      </div>
                    </td>
                    {Object.entries(metrics).map(([type, data]) => (
                      <td key={type} className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 hidden lg:table-cell">
                        {data.total > 0 ? (
                          <div className="flex flex-col">
                            <span>{((data.correct / data.total) * 100).toFixed(1)}%</span>
                            <span className="text-xs text-gray-500">
                              ({data.correct}/{data.total})
                            </span>
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    ))}
                  </tr>
                  {isExpanded && (
                    <tr className="bg-gray-700/10">
                      <td colSpan="8" className="px-6 py-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                          {Object.entries(metrics).map(([type, data]) => (
                            <div key={type} className="bg-gray-800/50 p-4 rounded-lg">
                              <h4 className="text-sm font-medium text-gray-300 mb-2">
                                {type.replace(/([A-Z])/g, ' $1').trim()}
                              </h4>
                              <div className="grid grid-cols-2 gap-2 text-sm">
                                <div>
                                  <p className="text-gray-500">Accuracy</p>
                                  <p className="text-gray-300">
                                    {data.total > 0 ? ((data.correct / data.total) * 100).toFixed(1) : 0}%
                                  </p>
                                </div>
                                <div>
                                  <p className="text-gray-500">Confidence</p>
                                  <p className="text-gray-300">
                                    {(data.confidence * 100).toFixed(1)}%
                                  </p>
                                </div>
                                <div>
                                  <p className="text-gray-500">Correct</p>
                                  <p className="text-gray-300">{data.correct}</p>
                                </div>
                                <div>
                                  <p className="text-gray-500">Total</p>
                                  <p className="text-gray-300">{data.total}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PredictionTable; 