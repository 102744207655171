import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../services/firebase';
import { cancelSubscription } from '../services/stripe';
import { useNavigate } from 'react-router-dom';
import SubscribeButton from '../components/subscription/SubscribeButton';
import { motion } from 'framer-motion';
import { 
  UserCircleIcon, 
  CalendarIcon, 
  CreditCardIcon,
  ShieldCheckIcon,
  ClockIcon,
  XCircleIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';

// Helper function to format timestamps
const formatDate = (timestamp) => {
  if (!timestamp) return null;
  
  // Handle Firestore Timestamp objects
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp * 1000);
  
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }).format(date);
};

const Account = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const [error, setError] = useState('');

  const isAdmin = user?.email === 'mykelandrewstanley@gmail.com';

  useEffect(() => {
    if (!user) return;

    const subscriptionRef = collection(db, 'customers', user.uid, 'subscriptions');
    
    const unsubscribe = onSnapshot(subscriptionRef, (snapshot) => {
      console.log('Subscription docs:', snapshot.docs.map(doc => ({id: doc.id, ...doc.data()})));
      
      const activeSub = snapshot.docs.find(doc => {
        const data = doc.data();
        return data.status === 'active';
      });

      console.log('Active subscription found:', activeSub ? 'yes' : 'no');
      
      if (activeSub) {
        const subData = activeSub.data();
        
        if (!subscription) {
          window.rewardful('convert', {
            email: user.email
          });
          console.log('Rewardful conversion tracked for:', user.email);
        }

        setSubscription({
          id: activeSub.id,
          ...subData,
          formattedDates: {
            created: formatDate(subData.created),
            currentPeriodEnd: formatDate(subData.current_period_end),
            currentPeriodStart: formatDate(subData.current_period_start)
          },
          isCanceling: subData.cancel_at_period_end
        });
      } else {
        setSubscription(null);
      }
    });

    return () => unsubscribe();
  }, [user]);

  const handleCancelSubscription = async () => {
    if (!window.confirm('Are you sure you want to cancel your subscription?')) {
      return;
    }

    setCancelling(true);
    setError('');

    try {
      await cancelSubscription(user.uid, subscription.id);
      // The subscription status will be updated automatically through the Firebase listener
    } catch (err) {
      console.error('Error canceling subscription:', err);
      setError('Failed to cancel subscription. Please try again.');
    } finally {
      setCancelling(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <div className="min-h-screen bg-[#0B1120] py-12 px-4 sm:px-6 lg:px-8">
      {/* Background Patterns */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-4000"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_200px,rgba(120,119,198,0.3),transparent)]"></div>
        <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-[0.02]"></div>
      </div>

      <motion.div 
        className="max-w-4xl mx-auto space-y-8 relative"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Header Section */}
        <motion.div 
          className="text-center"
          variants={itemVariants}
        >
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-400 via-indigo-400 to-purple-500 bg-clip-text text-transparent">
            Account Settings
          </h1>
          <p className="mt-2 text-gray-400">Manage your profile and subscription</p>
        </motion.div>

        {/* Admin Button - Only shown for admin user */}
        {isAdmin && (
          <motion.div
            variants={itemVariants}
            className="flex justify-center"
          >
            <motion.button
              onClick={() => navigate('/admin')}
              className="group relative flex items-center space-x-2 px-6 py-3 backdrop-blur-sm bg-indigo-500/10 hover:bg-indigo-500/20 
                border border-indigo-500/20 hover:border-indigo-500/30 rounded-xl text-indigo-400 hover:text-indigo-300 
                transition-all duration-200"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <ChartBarIcon className="h-5 w-5" />
              <span className="font-medium">Admin Analytics Portal</span>
              <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-indigo-500/20 to-purple-500/20 opacity-0 
                group-hover:opacity-100 transition-opacity duration-200 pointer-events-none" />
            </motion.button>
          </motion.div>
        )}

        {/* Profile Card */}
        <motion.div 
          className="backdrop-blur-sm bg-white/[0.02] rounded-2xl border border-white/[0.05] overflow-hidden"
          variants={itemVariants}
        >
          <div className="p-6">
            <div className="flex items-center space-x-4">
              <motion.div 
                className="w-16 h-16 rounded-xl bg-gradient-to-br from-indigo-500 to-purple-500 flex items-center justify-center text-2xl font-bold text-white"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {user?.email[0].toUpperCase()}
              </motion.div>
              <div>
                <h2 className="text-xl font-semibold text-white">Profile Details</h2>
                <p className="text-gray-400 mt-1">{user?.email}</p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Subscription Card */}
        <motion.div 
          className="backdrop-blur-sm bg-white/[0.02] rounded-2xl border border-white/[0.05] overflow-hidden"
          variants={itemVariants}
        >
          <div className="p-6 space-y-6">
            <h2 className="text-xl font-semibold text-white mb-6">Subscription Status</h2>
            
            {error && (
              <motion.div 
                className="p-4 rounded-xl bg-red-500/10 border border-red-500/50 text-red-400"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <div className="flex items-center space-x-2">
                  <XCircleIcon className="h-5 w-5" />
                  <span>{error}</span>
                </div>
              </motion.div>
            )}
            
            {subscription ? (
              <div className="space-y-6">
                {/* Subscription Status Banner */}
                <motion.div 
                  className={`p-6 rounded-xl ${
                    subscription.isCanceling 
                      ? 'bg-yellow-500/10 border border-yellow-500/50' 
                      : 'bg-green-500/10 border border-green-500/50'
                  }`}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <div className={`font-medium flex items-center gap-2 ${
                        subscription.isCanceling ? 'text-yellow-400' : 'text-green-400'
                      }`}>
                        <ShieldCheckIcon className="h-5 w-5" />
                        <span>{subscription.isCanceling ? 'Subscription (Canceling)' : 'Active Subscription'}</span>
                      </div>
                      <div className="text-sm text-gray-400 flex items-center gap-2">
                        <CalendarIcon className="h-4 w-4" />
                        <span>Started: {subscription.formattedDates.created}</span>
                      </div>
                    </div>
                    {!subscription.isCanceling && (
                      <motion.button
                        onClick={handleCancelSubscription}
                        disabled={cancelling}
                        className={`
                          px-4 py-2 rounded-xl text-sm font-medium transition-all duration-200
                          ${cancelling 
                            ? 'bg-gray-700/50 text-gray-400 cursor-not-allowed' 
                            : 'bg-red-500/10 text-red-400 hover:bg-red-500/20 hover:text-red-300'}
                        `}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        {cancelling ? 'Cancelling...' : 'Cancel Subscription'}
                      </motion.button>
                    )}
                  </div>
                </motion.div>

                {/* Next Billing Info */}
                <motion.div 
                  className="flex items-center space-x-2 text-sm text-gray-400 bg-white/[0.02] p-4 rounded-xl"
                  whileHover={{ scale: 1.02 }}
                >
                  <ClockIcon className="h-5 w-5" />
                  <span>
                    {subscription.isCanceling 
                      ? `Access until: ${subscription.formattedDates.currentPeriodEnd}`
                      : `Next billing date: ${subscription.formattedDates.currentPeriodEnd}`
                    }
                  </span>
                </motion.div>
              </div>
            ) : (
              <motion.div 
                className="space-y-6"
                variants={itemVariants}
              >
                <div className="text-gray-400 bg-white/[0.02] p-6 rounded-xl border border-white/[0.05]">
                  <div className="flex items-center gap-3 mb-4">
                    <CreditCardIcon className="h-6 w-6 text-indigo-400" />
                    <span>No active subscription found. Subscribe now to access premium features!</span>
                  </div>
                  <SubscribeButton />
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Account;