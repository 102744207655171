import React from 'react';
import { useGames } from '../hooks/useGames';
import GameCard from '../components/games/GameCard';
import { motion } from 'framer-motion';
import { SparklesIcon, TrophyIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';

const GamesTest = () => {
  const { games, loading, error } = useGames();

  // Sort games by date
  const sortedGames = [...games].sort((a, b) => {
    const dateA = new Date(a.date?.timestamp ? a.date.timestamp * 1000 : a.date);
    const dateB = new Date(b.date?.timestamp ? b.date.timestamp * 1000 : b.date);
    return dateA - dateB;
  });

  const openAffiliateSignup = () => {
    window.open('https://strategixai.getrewardful.com/signup', '_blank');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0B1120] flex justify-center items-center">
        <div className="relative">
          {/* Enhanced loading animation */}
          <motion.div 
            className="w-32 h-32 relative"
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          >
            <div className="absolute inset-0 rounded-full border-4 border-indigo-500/20" />
            <motion.div 
              className="absolute inset-0 rounded-full border-t-4 border-indigo-500"
              animate={{ rotate: 360 }}
              transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
            />
            <motion.div 
              className="absolute inset-4 rounded-full border-t-4 border-purple-500"
              animate={{ rotate: -360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            />
            <motion.div 
              className="absolute inset-8 rounded-full border-t-4 border-blue-500"
              animate={{ rotate: 360 }}
              transition={{ duration: 2.5, repeat: Infinity, ease: "linear" }}
            />
          </motion.div>
          <motion.div 
            className="absolute inset-0 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-blue-500/20 blur-xl"
            animate={{ 
              opacity: [0.5, 1, 0.5],
              scale: [0.8, 1.1, 0.8]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#0B1120] flex justify-center items-center p-4">
        <motion.div 
          className="backdrop-blur-sm bg-red-500/10 border border-red-500/30 rounded-2xl p-6 max-w-md w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="text-red-400 text-center">
            <h3 className="text-xl font-semibold mb-2">Error Loading Games</h3>
            <p>{error.message}</p>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <motion.div 
      className="min-h-screen bg-[#0B1120]"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Stats Banner */}
      <motion.div 
        className="bg-gradient-to-r from-indigo-500/5 via-purple-500/5 to-indigo-500/5 border-b border-white/5"
        variants={itemVariants}
      >
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-wrap items-center justify-center gap-6">
            <motion.div 
              className="flex items-center gap-2 bg-green-500/10 text-green-400 px-6 py-2 rounded-xl border border-green-500/20"
              whileHover={{ scale: 1.05 }}
            >
              <TrophyIcon className="w-5 h-5" />
              <span>Last 7 Days: NFL Win Rate: 71.45%</span>
            </motion.div>
            <motion.div 
              className="flex items-center gap-2 bg-blue-500/10 text-blue-400 px-6 py-2 rounded-xl border border-blue-500/20"
              whileHover={{ scale: 1.05 }}
            >
              <ArrowTrendingUpIcon className="w-5 h-5" />
              <span>Last 7 Days: NBA Win Rate: 70.3%</span>
            </motion.div>
            <motion.button
              onClick={openAffiliateSignup}
              className="flex items-center gap-2 bg-gradient-to-r from-emerald-500 to-teal-500 text-white 
                px-6 py-2 rounded-xl font-medium hover:opacity-90 transition-all transform 
                hover:scale-105 active:scale-100 shadow-lg border border-emerald-400/30"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <SparklesIcon className="w-5 h-5" />
              <span>Become an Affiliate</span>
            </motion.button>
          </div>
        </div>
      </motion.div>

      {/* Background Patterns */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_200px,rgba(120,119,198,0.3),transparent)]" />
        <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-[0.02]" />
      </div>

      {/* Games Grid */}
      <div className="relative max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <motion.div 
          className="grid grid-cols-1 lg:grid-cols-2 gap-8"
          variants={containerVariants}
        >
          {sortedGames.map((game, index) => (
            <motion.div
              key={game.id}
              variants={itemVariants}
              className="group relative transform perspective-1000"
              custom={index}
            >
              {/* Card Glow Effect */}
              <motion.div 
                className="absolute -inset-[1px] bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 
                  rounded-2xl blur-lg group-hover:blur-xl transition-all duration-300 opacity-0 group-hover:opacity-100"
                animate={{ 
                  opacity: [0, 0.5, 0],
                  scale: [0.95, 1.05, 0.95]
                }}
                transition={{ duration: 4, repeat: Infinity }}
              />
              
              {/* Card Container */}
              <motion.div 
                className="relative bg-white/[0.02] rounded-2xl border border-white/[0.05] overflow-hidden
                  transform-gpu transition-transform duration-300 hover:-translate-y-1"
                whileHover={{ scale: 1.02 }}
              >
                <GameCard game={game} />
                
                {/* Card Reflection */}
                <div className="absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-blue-500/[0.03] to-transparent 
                  rounded-b-2xl pointer-events-none" />
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default GamesTest;
