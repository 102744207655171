import React from 'react';
import InfoTooltip from '../../../common/InfoTooltip';
import { motion } from 'framer-motion';

const NBAPredictions = ({ predictions }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.3,
        ease: "easeOut"
      }
    })
  };

  return (
    <div className="space-y-4">
      {/* Main Game Predictions */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        {/* Game Winner */}
        <motion.div 
          className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 p-4 rounded-xl border border-white/5"
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          custom={0}
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300, damping: 15 }}
        >
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
          <div className="relative">
            <div className="flex items-center space-x-2">
              <div className="text-xs text-gray-400 font-medium">Game Winner</div>
              <InfoTooltip content={predictions?.gameWinner?.reasoning} />
            </div>
            <div className="flex justify-between items-center mt-2">
              <div className="text-sm font-medium text-white">
                {predictions?.gameWinner?.winner || 'Analyzing...'}
              </div>
              <motion.div 
                className="text-sm font-bold bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent"
                animate={{ 
                  opacity: [0.8, 1, 0.8],
                  scale: [1, 1.05, 1]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                {predictions?.gameWinner?.confidence}%
              </motion.div>
            </div>
          </div>
        </motion.div>

        {/* Total Points */}
        <motion.div 
          className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 p-4 rounded-xl border border-white/5"
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          custom={1}
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300, damping: 15 }}
        >
          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/[0.02] via-transparent to-blue-500/[0.02] animate-pulse" />
          <div className="relative">
            <div className="flex items-center space-x-2">
              <div className="text-xs text-gray-400 font-medium">Total Points</div>
              <InfoTooltip content={predictions?.totalPoints?.reasoning} />
            </div>
            <div className="space-y-2 mt-2">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-1">
                  <span className={`text-xs font-medium px-2 py-0.5 rounded ${
                    predictions?.totalPoints?.overUnderRecommendation === 'OVER' 
                      ? 'bg-green-500/20 text-green-400' 
                      : 'bg-red-500/20 text-red-400'
                  }`}>
                    {predictions?.totalPoints?.overUnderRecommendation || ''}
                  </span>
                  <span className="text-sm font-medium text-white">
                    {predictions?.totalPoints?.total || 'Analyzing...'}
                  </span>
                </div>
                <motion.div 
                  className="text-sm font-bold bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent"
                  animate={{ 
                    opacity: [0.8, 1, 0.8],
                    scale: [1, 1.05, 1]
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  {predictions?.totalPoints?.confidence}%
                </motion.div>
              </div>
              
              {/* Key Factors */}
              {predictions?.totalPoints?.keyFactors && (
                <div className="flex flex-wrap gap-2">
                  {predictions.totalPoints.keyFactors.paceImpact && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded bg-indigo-500/20 text-indigo-400 text-xs">
                      Pace: {predictions.totalPoints.keyFactors.paceImpact.split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ')}
                    </span>
                  )}
                  {predictions.totalPoints.keyFactors.shootingEfficiency && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded bg-violet-500/20 text-violet-400 text-xs">
                      Efficiency: {predictions.totalPoints.keyFactors.shootingEfficiency.split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ')}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>

      {/* Player Performance Predictions */}
      <div className="mt-6">
        <motion.h4 
          className="text-sm font-medium text-gray-400 mb-3 flex items-center space-x-2"
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          custom={2}
        >
          <span>Player Performance Predictions</span>
          <motion.div 
            className="w-2 h-2 rounded-full bg-blue-500"
            animate={{ 
              scale: [1, 1.5, 1],
              opacity: [0.5, 1, 0.5]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          />
        </motion.h4>
        
        <div className="space-y-3">
          {predictions?.playerPerformance?.playerPerformance?.predictions?.map((player, index) => (
            <motion.div 
              key={index} 
              className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 p-4 rounded-xl border border-white/5"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              custom={index + 3}
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300, damping: 15 }}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
              <div className="relative">
                <div className="flex items-center justify-between mb-3">
                  <div className="text-white font-medium">{player.playerName}</div>
                  <motion.div 
                    className="text-sm font-bold bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent"
                    animate={{ 
                      opacity: [0.8, 1, 0.8],
                      scale: [1, 1.05, 1]
                    }}
                    transition={{ duration: 2, repeat: Infinity }}
                  >
                    {player.confidence}%
                  </motion.div>
                </div>
                
                <div className="grid grid-cols-3 gap-4 mb-3">
                  <div className="bg-gray-800/30 p-2 rounded-lg">
                    <div className="text-xs text-gray-400 mb-1">Points</div>
                    <div className="text-sm font-medium text-white">{player.points}</div>
                  </div>
                  <div className="bg-gray-800/30 p-2 rounded-lg">
                    <div className="text-xs text-gray-400 mb-1">Rebounds</div>
                    <div className="text-sm font-medium text-white">{player.rebounds}</div>
                  </div>
                  <div className="bg-gray-800/30 p-2 rounded-lg">
                    <div className="text-xs text-gray-400 mb-1">Assists</div>
                    <div className="text-sm font-medium text-white">{player.assists}</div>
                  </div>
                </div>
                
                <div className="text-sm text-gray-400 leading-relaxed">
                  {player.reasoning}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NBAPredictions; 