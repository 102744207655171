import React, { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useBacktestingAnalytics } from '../../hooks/useBacktestingAnalytics';
import {
  ChartBarIcon,
  ArrowTrendingUpIcon,
  FireIcon,
  ChartPieIcon,
} from '@heroicons/react/24/outline';

const TeamAnalytics = () => {
  const [timeRange, setTimeRange] = useState('all');
  const { predictions, loading } = useBacktestingAnalytics(timeRange);
  const [selectedBetType, setSelectedBetType] = useState('all');

  const teamStats = useMemo(() => {
    if (!predictions?.length) return { teams: [], topPerformers: [], betTypeBreakdown: {} };

    const teamMap = new Map();

    // Process each prediction
    predictions.forEach(prediction => {
      const homeTeam = prediction.teams?.home?.name;
      const awayTeam = prediction.teams?.away?.name;
      if (!homeTeam || !awayTeam) return;

      // Initialize teams if not exists
      [homeTeam, awayTeam].forEach(team => {
        if (!teamMap.has(team)) {
          teamMap.set(team, {
            name: team,
            betTypes: {
              gameWinner: { correct: 0, total: 0 },
              pointSpread: { correct: 0, total: 0 },
              totalPoints: { correct: 0, total: 0 },
              playerProps: { correct: 0, total: 0 }
            },
            totalCorrect: 0,
            totalPredictions: 0
          });
        }
      });

      // Process game winner
      if (prediction.results?.gameWinner) {
        const winnerTeam = prediction.results.gameWinner.winner === 'home' ? homeTeam : awayTeam;
        const teamData = teamMap.get(winnerTeam);
        if (teamData) {
          teamData.betTypes.gameWinner.total++;
          teamData.totalPredictions++;
          if (prediction.results.gameWinner.correct) {
            teamData.betTypes.gameWinner.correct++;
            teamData.totalCorrect++;
          }
        }
      }

      // Process point spread
      if (prediction.results?.pointSpread) {
        [homeTeam, awayTeam].forEach(team => {
          const teamData = teamMap.get(team);
          teamData.betTypes.pointSpread.total++;
          teamData.totalPredictions++;
          if (prediction.results.pointSpread.correct) {
            teamData.betTypes.pointSpread.correct++;
            teamData.totalCorrect++;
          }
        });
      }

      // Process total points
      if (prediction.results?.totalPoints) {
        [homeTeam, awayTeam].forEach(team => {
          const teamData = teamMap.get(team);
          teamData.betTypes.totalPoints.total++;
          teamData.totalPredictions++;
          if (prediction.results.totalPoints.correct) {
            teamData.betTypes.totalPoints.correct++;
            teamData.totalCorrect++;
          }
        });
      }

      // Process player props
      if (prediction.results?.players) {
        Object.entries(prediction.results.players).forEach(([playerName, playerResults]) => {
          Object.entries(playerResults.correct || {}).forEach(([stat, isCorrect]) => {
            const teamData = teamMap.get(homeTeam); // Assuming player belongs to home team
            if (teamData) {
              teamData.betTypes.playerProps.total++;
              teamData.totalPredictions++;
              if (isCorrect) {
                teamData.betTypes.playerProps.correct++;
                teamData.totalCorrect++;
              }
            }
          });
        });
      }
    });

    // Convert to array and calculate percentages
    const teams = Array.from(teamMap.values())
      .map(team => ({
        ...team,
        accuracy: (team.totalCorrect / team.totalPredictions) * 100,
        betTypes: Object.entries(team.betTypes).reduce((acc, [type, data]) => ({
          ...acc,
          [type]: {
            ...data,
            accuracy: data.total > 0 ? (data.correct / data.total) * 100 : 0
          }
        }), {})
      }))
      .filter(team => team.totalPredictions >= 10); // Only include teams with sufficient predictions

    // Get top performers
    const topPerformers = {
      overall: [...teams].sort((a, b) => b.accuracy - a.accuracy).slice(0, 5),
      gameWinner: [...teams]
        .filter(t => t.betTypes.gameWinner.total >= 5)
        .sort((a, b) => b.betTypes.gameWinner.accuracy - a.betTypes.gameWinner.accuracy)
        .slice(0, 5),
      pointSpread: [...teams]
        .filter(t => t.betTypes.pointSpread.total >= 5)
        .sort((a, b) => b.betTypes.pointSpread.accuracy - a.betTypes.pointSpread.accuracy)
        .slice(0, 5),
      totalPoints: [...teams]
        .filter(t => t.betTypes.totalPoints.total >= 5)
        .sort((a, b) => b.betTypes.totalPoints.accuracy - a.betTypes.totalPoints.accuracy)
        .slice(0, 5),
      playerProps: [...teams]
        .filter(t => t.betTypes.playerProps.total >= 5)
        .sort((a, b) => b.betTypes.playerProps.accuracy - a.betTypes.playerProps.accuracy)
        .slice(0, 5)
    };

    // Calculate bet type breakdown
    const betTypeBreakdown = {
      gameWinner: teams.reduce((acc, t) => ({
        correct: acc.correct + t.betTypes.gameWinner.correct,
        total: acc.total + t.betTypes.gameWinner.total
      }), { correct: 0, total: 0 }),
      pointSpread: teams.reduce((acc, t) => ({
        correct: acc.correct + t.betTypes.pointSpread.correct,
        total: acc.total + t.betTypes.pointSpread.total
      }), { correct: 0, total: 0 }),
      totalPoints: teams.reduce((acc, t) => ({
        correct: acc.correct + t.betTypes.totalPoints.correct,
        total: acc.total + t.betTypes.totalPoints.total
      }), { correct: 0, total: 0 }),
      playerProps: teams.reduce((acc, t) => ({
        correct: acc.correct + t.betTypes.playerProps.correct,
        total: acc.total + t.betTypes.playerProps.total
      }), { correct: 0, total: 0 })
    };

    return { teams, topPerformers, betTypeBreakdown };
  }, [predictions]);

  if (loading) {
    return (
      <div className="animate-pulse space-y-6">
        <div className="h-8 bg-gray-700 rounded w-1/4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="h-32 bg-gray-700 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  const betTypes = [
    { id: 'all', name: 'Overall' },
    { id: 'gameWinner', name: 'Game Winner' },
    { id: 'pointSpread', name: 'Point Spread' },
    { id: 'totalPoints', name: 'Total Points' },
    { id: 'playerProps', name: 'Player Props' }
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      {/* Filters */}
      <div className="flex flex-wrap gap-4">
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="bg-gray-800 text-gray-200 rounded-lg px-4 py-2 border border-gray-700"
        >
          <option value="all">All Time</option>
          <option value="7">Last 7 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="90">Last 90 Days</option>
        </select>

        <div className="flex gap-2">
          {betTypes.map((type) => (
            <button
              key={type.id}
              onClick={() => setSelectedBetType(type.id)}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                selectedBetType === type.id
                  ? 'bg-indigo-500/10 text-indigo-400'
                  : 'text-gray-400 hover:text-white hover:bg-white/5'
              }`}
            >
              {type.name}
            </button>
          ))}
        </div>
      </div>

      {/* Summary Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Teams Tracked"
          value={teamStats.teams.length}
          icon={ChartBarIcon}
          color="blue"
        />
        <StatCard
          title="Overall Success Rate"
          value={`${((teamStats.teams.reduce((sum, t) => sum + t.totalCorrect, 0) / 
                     teamStats.teams.reduce((sum, t) => sum + t.totalPredictions, 0)) * 100).toFixed(1)}%`}
          icon={ArrowTrendingUpIcon}
          color="green"
        />
        <StatCard
          title="Most Accurate Team"
          value={teamStats.topPerformers.overall[0]?.name || 'N/A'}
          subtitle={`${teamStats.topPerformers.overall[0]?.accuracy.toFixed(1)}% Success`}
          icon={FireIcon}
          color="orange"
        />
        <StatCard
          title="Best Bet Type"
          value={Object.entries(teamStats.betTypeBreakdown)
            .map(([type, data]) => ({
              type,
              accuracy: (data.correct / data.total) * 100
            }))
            .sort((a, b) => b.accuracy - a.accuracy)[0]?.type
            .replace(/([A-Z])/g, ' $1')
            .trim() || 'N/A'}
          subtitle={`${Object.entries(teamStats.betTypeBreakdown)
            .map(([type, data]) => ({
              type,
              accuracy: (data.correct / data.total) * 100
            }))
            .sort((a, b) => b.accuracy - a.accuracy)[0]?.accuracy.toFixed(1)}% Success`}
          icon={ChartPieIcon}
          color="purple"
        />
      </div>

      {/* Top Performers */}
      <div className="bg-gray-800/50 rounded-xl p-6">
        <h3 className="text-lg font-medium text-gray-200 mb-4">
          Top Teams {selectedBetType !== 'all' ? `- ${betTypes.find(t => t.id === selectedBetType)?.name}` : ''}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {(selectedBetType === 'all' ? teamStats.topPerformers.overall : teamStats.topPerformers[selectedBetType])
            .map((team, index) => (
              <motion.div
                key={team.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-700/30 rounded-lg p-4"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="text-white font-medium">{team.name}</div>
                  <div className="text-indigo-400 font-bold">
                    {selectedBetType === 'all' 
                      ? `${team.accuracy.toFixed(1)}%`
                      : `${team.betTypes[selectedBetType].accuracy.toFixed(1)}%`}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div>
                    <div className="text-gray-400">Game Winner</div>
                    <div className="text-gray-200">{team.betTypes.gameWinner.accuracy.toFixed(1)}%</div>
                    <div className="text-xs text-gray-500">
                      ({team.betTypes.gameWinner.correct}/{team.betTypes.gameWinner.total})
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Point Spread</div>
                    <div className="text-gray-200">{team.betTypes.pointSpread.accuracy.toFixed(1)}%</div>
                    <div className="text-xs text-gray-500">
                      ({team.betTypes.pointSpread.correct}/{team.betTypes.pointSpread.total})
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Total Points</div>
                    <div className="text-gray-200">{team.betTypes.totalPoints.accuracy.toFixed(1)}%</div>
                    <div className="text-xs text-gray-500">
                      ({team.betTypes.totalPoints.correct}/{team.betTypes.totalPoints.total})
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Player Props</div>
                    <div className="text-gray-200">{team.betTypes.playerProps.accuracy.toFixed(1)}%</div>
                    <div className="text-xs text-gray-500">
                      ({team.betTypes.playerProps.correct}/{team.betTypes.playerProps.total})
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
        </div>
      </div>

      {/* Detailed Stats Table */}
      <div className="bg-gray-800/50 rounded-xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-gray-900/50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Team
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Overall
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Game Winner
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Point Spread
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Total Points
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Player Props
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {teamStats.teams
                .sort((a, b) => b.accuracy - a.accuracy)
                .map((team) => (
                  <tr key={team.name} className="hover:bg-gray-700/30">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {team.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center">
                        <div className={`w-2 h-2 rounded-full mr-2 ${
                          team.accuracy >= 70 ? 'bg-green-500' :
                          team.accuracy >= 50 ? 'bg-yellow-500' :
                          'bg-red-500'
                        }`}></div>
                        <span className="text-gray-300">{team.accuracy.toFixed(1)}%</span>
                        <span className="text-gray-500 text-xs ml-2">
                          ({team.totalCorrect}/{team.totalPredictions})
                        </span>
                      </div>
                    </td>
                    {['gameWinner', 'pointSpread', 'totalPoints', 'playerProps'].map(type => (
                      <td key={type} className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {team.betTypes[type].total > 0 ? (
                          <div className="flex items-center">
                            <div className={`w-2 h-2 rounded-full mr-2 ${
                              team.betTypes[type].accuracy >= 70 ? 'bg-green-500' :
                              team.betTypes[type].accuracy >= 50 ? 'bg-yellow-500' :
                              'bg-red-500'
                            }`}></div>
                            <span>{team.betTypes[type].accuracy.toFixed(1)}%</span>
                            <span className="text-gray-500 text-xs ml-2">
                              ({team.betTypes[type].correct}/{team.betTypes[type].total})
                            </span>
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </motion.div>
  );
};

const StatCard = ({ title, value, subtitle, icon: Icon, color }) => {
  const colorClasses = {
    blue: 'text-blue-400',
    green: 'text-green-400',
    orange: 'text-orange-400',
    purple: 'text-purple-400'
  };

  return (
    <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/50">
      <div className="flex items-center justify-between">
        <div className="w-12 h-12 rounded-lg bg-gray-700/50 flex items-center justify-center">
          <Icon className={`w-6 h-6 ${colorClasses[color]}`} />
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-gray-400 text-sm">{title}</h3>
        <p className={`text-2xl font-semibold mt-1 ${colorClasses[color]}`}>{value}</p>
        {subtitle && (
          <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
        )}
      </div>
    </div>
  );
};

export default TeamAnalytics; 