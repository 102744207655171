import { useState, useEffect, useMemo } from 'react';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';

export const useBacktestingAnalytics = (timeRange = 'all', betType = 'all') => {
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  // Get the date range for filtering
  const getTimeRangeDate = (range) => {
    const now = new Date();
    switch (range) {
      case '7d':
        return new Date(now.setDate(now.getDate() - 7));
      case '30d':
        return new Date(now.setDate(now.getDate() - 30));
      case '90d':
        return new Date(now.setDate(now.getDate() - 90));
      case 'ytd':
        return new Date(now.getFullYear(), 0, 1); // Start of current year
      default:
        return new Date(0); // All time
    }
  };

  useEffect(() => {
    const fetchPredictions = async () => {
      setLoading(true);
      try {
        if (!user || user.email !== 'mykelandrewstanley@gmail.com') {
          throw new Error('Unauthorized access');
        }

        const backtestingRef = collection(db, 'nba_backtesting');
        const q = query(
          backtestingRef,
          orderBy('gameDate', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        
        // Process and filter the documents
        const predictionsData = querySnapshot.docs
          .map(doc => {
            const data = doc.data();
            
            // Ensure gameDate is a valid Date object
            let gameDate;
            try {
              gameDate = data.gameDate ? new Date(data.gameDate) : new Date(data.testedAt || Date.now());
              if (isNaN(gameDate.getTime())) {
                gameDate = new Date(); // Fallback to current date if invalid
              }
            } catch (e) {
              gameDate = new Date();
            }

            return {
              id: doc.id,
              gameId: data.gameId,
              gameDate,
              teams: data.teams || {},
              results: {
                gameWinner: data.results?.gameWinner || null,
                pointSpread: data.results?.pointSpread || null,
                totalPoints: data.results?.totalPoints || null,
                players: data.results?.players || {}
              },
              confidence: {
                gameWinner: parseFloat(data.prediction?.gameWinner?.confidence || data.confidence?.gameWinner || 0),
                pointSpread: parseFloat(data.prediction?.pointSpread?.confidence || data.confidence?.pointSpread || 0),
                totalPoints: parseFloat(data.prediction?.totalPoints?.confidence || data.confidence?.totalPoints || 0),
                players: Object.entries(data.prediction?.players || {}).reduce((acc, [key, value]) => {
                  acc[key] = parseFloat(value.confidence || 0);
                  return acc;
                }, {})
              },
              status: data.status || 'completed'
            };
          })
          .filter(pred => {
            if (timeRange === 'all') return true;
            const cutoffDate = getTimeRangeDate(timeRange);
            return pred.gameDate >= cutoffDate;
          });

        setPredictions(predictionsData);
        setError(null);
      } catch (err) {
        console.error('Error fetching backtesting data:', err);
        setError(err.message);
        setPredictions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPredictions();
  }, [timeRange, user]);

  const stats = useMemo(() => {
    if (!predictions.length) return null;

    const filteredPredictions = betType === 'all' 
      ? predictions 
      : predictions.filter(p => p.results[betType]);

    const calculateAccuracy = (preds, type) => {
      if (type === 'totalPoints') {
        const relevantPreds = preds.filter(p => p.results[type]);
        if (!relevantPreds.length) return {
          accuracy: 0,
          avgConfidence: 0,
          total: 0,
          correct: 0,
          successRate: 0
        };

        const correct = relevantPreds.filter(p => {
          // Check if the over/under recommendation was correct
          const result = p.results[type];
          return result.correct;  // This now comes directly from our new validation
        }).length;

        const totalConfidence = relevantPreds.reduce((sum, p) => sum + (p.confidence[type] * 100), 0);
        const accuracy = (correct / relevantPreds.length) * 100;
        const avgConfidence = totalConfidence / relevantPreds.length;

        return {
          accuracy,
          avgConfidence,
          total: relevantPreds.length,
          correct,
          successRate: accuracy / (avgConfidence || 1),
          // Add additional metrics specific to totalPoints
          averageMargin: relevantPreds.reduce((sum, p) => sum + Math.abs(p.results[type].margin), 0) / relevantPreds.length
        };
      }

      const relevantPreds = preds.filter(p => p.results[type]);
      if (!relevantPreds.length) return {
        accuracy: 0,
        avgConfidence: 0,
        total: 0,
        correct: 0,
        successRate: 0
      };
      
      const correct = relevantPreds.filter(p => p.results[type].correct).length;
      const totalConfidence = relevantPreds.reduce((sum, p) => sum + (p.confidence[type] * 100), 0);
      const accuracy = (correct / relevantPreds.length) * 100;
      const avgConfidence = totalConfidence / relevantPreds.length;
      
      return {
        accuracy,
        avgConfidence,
        total: relevantPreds.length,
        correct,
        successRate: accuracy / (avgConfidence || 1) // How well confidence predicts accuracy
      };
    };

    const betTypes = ['gameWinner', 'pointSpread', 'totalPoints', 'players'];
    const accuracies = betTypes.map(type => ({
      type,
      ...calculateAccuracy(filteredPredictions, type)
    }));

    const bestBetType = accuracies.reduce((a, b) => 
      a.accuracy > b.accuracy ? a : b
    );

    // Calculate recent trend (last 7 days vs previous 7 days)
    const now = new Date();
    const last7Days = new Date(now.setDate(now.getDate() - 7));
    const previous7Days = new Date(now.setDate(now.getDate() - 7));
    
    const recentPreds = predictions.filter(p => p.gameDate >= last7Days);
    const previousPreds = predictions.filter(p => p.gameDate >= previous7Days && p.gameDate < last7Days);
    
    const recentAccuracy = calculateAccuracy(recentPreds, betType === 'all' ? 'gameWinner' : betType);
    const previousAccuracy = calculateAccuracy(previousPreds, betType === 'all' ? 'gameWinner' : betType);
    
    const trend = recentAccuracy.accuracy - previousAccuracy.accuracy;

    return {
      totalPredictions: filteredPredictions.length,
      overallAccuracy: calculateAccuracy(filteredPredictions, betType === 'all' ? 'gameWinner' : betType).accuracy,
      bestBetType: bestBetType.type,
      bestBetTypeAccuracy: bestBetType.accuracy,
      recentTrend: trend,
      accuraciesByType: Object.fromEntries(
        accuracies.map(({ type, accuracy, avgConfidence, total, correct, successRate }) => [
          type, 
          { accuracy, avgConfidence, total, correct, successRate }
        ])
      ),
      playerPropsAccuracy: calculateAccuracy(filteredPredictions, 'players').accuracy
    };
  }, [predictions, betType]);

  return {
    predictions,
    stats,
    loading,
    error
  };
};
