import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { 
  BoltIcon, 
  ChartBarIcon, 
  ChatBubbleBottomCenterTextIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowTrendingUpIcon,
  TrophyIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';
import GamePreviewScroll from '../components/preview/GamePreviewScroll';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    console.log('Starting login attempt...');
    
    try {
      await login(formData.email, formData.password);
      window.gtag?.('event', 'login_success', {
        'event_category': 'authentication'
      });
      console.log('Login successful, navigating to home...');
      navigate('/');
    } catch (error) {
      window.gtag?.('event', 'login_failure', {
        'event_category': 'authentication'
      });
      console.error('Login error:', error);
      setError('Invalid email or password');
    }
  };

  const openAffiliateSignup = () => {
    window.open('https://strategixai.getrewardful.com/signup', '_blank');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <div className="min-h-screen relative overflow-hidden bg-[#0B1120]">
      {/* Animated background patterns */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-4000"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_200px,rgba(120,119,198,0.3),transparent)]"></div>
        <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-[0.02]"></div>
      </div>

      <div className="container mx-auto px-4 py-12 relative z-10">
        <motion.div
          className="max-w-7xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className="text-center mb-12"
            variants={itemVariants}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-400 via-indigo-400 to-purple-500 bg-clip-text text-transparent">
              AI-Powered Sports Predictions
            </h1>
            <p className="text-xl md:text-2xl text-gray-300 mb-8 max-w-3xl mx-auto">
              Join thousands of smart bettors using AI to make data-driven decisions
            </p>
            <motion.div 
              className="flex flex-wrap justify-center gap-4 mb-8"
              variants={itemVariants}
            >
              <div className="flex items-center gap-2 bg-green-500/20 text-green-400 px-6 py-3 rounded-xl border border-green-500/30">
                <TrophyIcon className="w-5 h-5" />
                <span>NFL Win Rate: 74.3%</span>
              </div>
              <div className="flex items-center gap-2 bg-blue-500/20 text-blue-400 px-6 py-3 rounded-xl border border-blue-500/30">
                <ArrowTrendingUpIcon className="w-5 h-5" />
                <span>NBA Win Rate: 76.2%</span>
              </div>
              <div className="flex items-center gap-2 bg-purple-500/20 text-purple-400 px-6 py-3 rounded-xl border border-purple-500/30">
                <SparklesIcon className="w-5 h-5" />
                <span>1800+ Active Members</span>
              </div>
            </motion.div>
          </motion.div>

          {/* Preview Section */}
          <motion.div
            variants={itemVariants}
            className="mb-12"
          >
            <GamePreviewScroll />
          </motion.div>

          <div className="flex flex-col lg:flex-row items-start gap-12">
            {/* Login Form */}
            <motion.div 
              variants={itemVariants}
              className="w-full lg:w-5/12 backdrop-blur-sm bg-white/[0.02] p-8 rounded-2xl shadow-2xl border border-white/[0.05]"
            >
              <h2 className="text-2xl font-bold text-white mb-6">Welcome Back</h2>
              
              {error && (
                <motion.div 
                  className="bg-red-500/10 border border-red-500/50 text-red-400 rounded-xl p-4 mb-6"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                    <span>{error}</span>
                  </div>
                </motion.div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-gray-300 mb-2 font-medium">Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    className="w-full bg-white/[0.03] rounded-xl p-4 text-white border border-white/[0.05]
                      focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all
                      placeholder:text-gray-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-300 mb-2 font-medium">Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={formData.password}
                      onChange={(e) => setFormData({...formData, password: e.target.value})}
                      className="w-full bg-white/[0.03] rounded-xl p-4 text-white border border-white/[0.05]
                        focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all
                        placeholder:text-gray-500 pr-12"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 
                        hover:text-gray-300 focus:outline-none"
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="h-5 w-5" />
                      ) : (
                        <EyeIcon className="h-5 w-5" />
                      )}
                    </button>
                  </div>
                </div>

                <motion.button
                  type="submit"
                  className="w-full bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl p-4 
                    font-semibold transition-all transform hover:scale-[1.02] active:scale-[0.98]
                    shadow-lg shadow-indigo-500/25 relative overflow-hidden group"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="relative z-10">Login</span>
                  <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-purple-600 opacity-0 
                    group-hover:opacity-100 transition-opacity" />
                </motion.button>

                <div className="text-center text-gray-400">
                  Don't have an account?{' '}
                  <Link to="/register" className="text-indigo-400 hover:text-indigo-300 font-semibold">
                    Sign up for free →
                  </Link>
                </div>
              </form>
            </motion.div>

            {/* Features Section */}
            <motion.div 
              variants={itemVariants}
              className="w-full lg:w-7/12 space-y-8"
            >
              {/* Value Proposition */}
              <div className="space-y-6">
                <h3 className="text-2xl font-bold text-white">Why Choose Our Platform?</h3>
                <div className="grid md:grid-cols-2 gap-6">
                  <motion.div 
                    className="backdrop-blur-sm bg-white/[0.02] p-6 rounded-2xl border border-white/[0.05]
                      hover:border-indigo-500/30 transition-all transform hover:scale-[1.02]"
                    whileHover={{ scale: 1.02 }}
                  >
                    <BoltIcon className="w-8 h-8 text-indigo-400 mb-4" />
                    <h4 className="text-lg font-semibold text-white mb-2">AI-Powered Insights</h4>
                    <p className="text-gray-400">Advanced algorithms analyze millions of data points for accurate predictions</p>
                  </motion.div>

                  <motion.div 
                    className="backdrop-blur-sm bg-white/[0.02] p-6 rounded-2xl border border-white/[0.05]
                      hover:border-purple-500/30 transition-all transform hover:scale-[1.02]"
                    whileHover={{ scale: 1.02 }}
                  >
                    <ChartBarIcon className="w-8 h-8 text-purple-400 mb-4" />
                    <h4 className="text-lg font-semibold text-white mb-2">Proven Track Record</h4>
                    <p className="text-gray-400">Consistently high win rates backed by historical data</p>
                  </motion.div>

                  <motion.div 
                    className="backdrop-blur-sm bg-white/[0.02] p-6 rounded-2xl border border-white/[0.05]
                      hover:border-blue-500/30 transition-all transform hover:scale-[1.02]"
                    whileHover={{ scale: 1.02 }}
                  >
                    <ChatBubbleBottomCenterTextIcon className="w-8 h-8 text-blue-400 mb-4" />
                    <h4 className="text-lg font-semibold text-white mb-2">Expert Community</h4>
                    <p className="text-gray-400">Join a community of successful bettors and share strategies</p>
                  </motion.div>

                  <motion.div 
                    className="backdrop-blur-sm bg-white/[0.02] p-6 rounded-2xl border border-white/[0.05]
                      hover:border-emerald-500/30 transition-all transform hover:scale-[1.02]"
                    whileHover={{ scale: 1.02 }}
                  >
                    <SparklesIcon className="w-8 h-8 text-emerald-400 mb-4" />
                    <h4 className="text-lg font-semibold text-white mb-2">Free Starter Plan</h4>
                    <p className="text-gray-400">Get started with our free tier and upgrade when you're ready</p>
                  </motion.div>
                </div>
              </div>

              {/* Testimonials */}
              <div className="space-y-6">
                <h3 className="text-2xl font-bold text-white">What Our Users Say</h3>
                <div className="grid md:grid-cols-2 gap-6">
                  <motion.div 
                    className="backdrop-blur-sm bg-white/[0.02] p-6 rounded-2xl border border-white/[0.05]"
                    whileHover={{ scale: 1.02 }}
                  >
                    <div className="flex items-center gap-2 mb-4">
                      {[1,2,3,4,5].map((star) => (
                        <svg key={star} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>
                    <p className="text-gray-300 mb-4">"The AI predictions have completely transformed my betting strategy. Up 32% since joining!"</p>
                    <div className="text-sm text-gray-400">- Michael R.</div>
                  </motion.div>

                  <motion.div 
                    className="backdrop-blur-sm bg-white/[0.02] p-6 rounded-2xl border border-white/[0.05]"
                    whileHover={{ scale: 1.02 }}
                  >
                    <div className="flex items-center gap-2 mb-4">
                      {[1,2,3,4,5].map((star) => (
                        <svg key={star} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>
                    <p className="text-gray-300 mb-4">"The accuracy of the predictions is incredible. Best investment in my betting career!"</p>
                    <div className="text-sm text-gray-400">- Sarah K.</div>
                  </motion.div>
                </div>
              </div>

              {/* Affiliate Banner */}
              <motion.button
                onClick={openAffiliateSignup}
                className="w-full bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-xl p-6 
                  font-semibold transition-all transform hover:scale-[1.02] active:scale-[0.98]
                  shadow-lg border border-emerald-400/30 group relative overflow-hidden"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="relative z-10 flex flex-col md:flex-row items-center justify-between gap-4">
                  <div className="flex items-center gap-3">
                    <span className="text-2xl">🚀</span>
                    <span className="text-xl">Become an Affiliate</span>
                  </div>
                  <div className="text-emerald-200">Earn 25% Monthly Commission on Each Referral</div>
                </div>
                <div className="absolute inset-0 bg-gradient-to-r from-emerald-600 to-teal-600 opacity-0 
                  group-hover:opacity-100 transition-opacity" />
              </motion.button>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Login;