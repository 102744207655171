export const calculateOverallAccuracy = (predictions) => {
  let totalCorrect = 0;
  let totalPredictions = 0;

  predictions.forEach(pred => {
    // Game winner
    if (pred.results.gameWinner) {
      totalPredictions++;
      if (pred.results.gameWinner.correct) totalCorrect++;
    }

    // Point spread
    if (pred.results.pointSpread) {
      totalPredictions++;
      if (pred.results.pointSpread.correct) totalCorrect++;
    }

    // Total points
    if (pred.results.totalPoints) {
      totalPredictions++;
      if (pred.results.totalPoints.correct) totalCorrect++;
    }

    // Player props
    if (pred.results.players) {
      Object.values(pred.results.players).forEach(player => {
        Object.entries(player.correct || {}).forEach(([stat, isCorrect]) => {
          totalPredictions++;
          if (isCorrect) totalCorrect++;
        });
      });
    }
  });

  return totalPredictions > 0 ? (totalCorrect / totalPredictions) * 100 : 0;
};

export const calculateRecentTrend = (predictions, days = 7) => {
  const now = new Date();
  const recentDate = new Date(now.setDate(now.getDate() - days));
  
  const recentPreds = predictions.filter(p => new Date(p.gameDate) >= recentDate);
  const olderPreds = predictions.filter(p => new Date(p.gameDate) < recentDate);
  
  const recentAccuracy = calculateOverallAccuracy(recentPreds);
  const olderAccuracy = calculateOverallAccuracy(olderPreds);
  
  return recentAccuracy - olderAccuracy;
};

export const getBestBetType = (predictions) => {
  const betTypes = [
    { type: 'gameWinner', name: 'Game Winner' },
    { type: 'pointSpread', name: 'Point Spread' },
    { type: 'totalPoints', name: 'Total Points' },
    { type: 'players', name: 'Player Props' }
  ];

  const accuracies = betTypes.map(bet => ({
    name: bet.name,
    accuracy: calculateBetTypeAccuracy(predictions, bet.type)
  }));

  return accuracies.reduce((best, current) => 
    current.accuracy > best.accuracy ? current : best
  ).name;
};

export const calculateBetTypeAccuracy = (predictions, type) => {
  if (type === 'players') {
    let totalProps = 0;
    let correctProps = 0;
    
    predictions.forEach(pred => {
      if (pred.results.players) {
        Object.values(pred.results.players).forEach(player => {
          Object.entries(player.correct || {}).forEach(([stat, isCorrect]) => {
            totalProps++;
            if (isCorrect) correctProps++;
          });
        });
      }
    });
    
    return totalProps > 0 ? (correctProps / totalProps) * 100 : 0;
  }

  const relevantPreds = predictions.filter(p => p.results[type]);
  if (!relevantPreds.length) return 0;
  
  const correct = relevantPreds.filter(p => p.results[type].correct).length;
  return (correct / relevantPreds.length) * 100;
}; 