import React from 'react';
import PredictionCard from '../../PredictionCard';
import { motion } from 'framer-motion';

const NFLPredictions = ({ predictions }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <motion.div 
      className="relative space-y-6 pb-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Background Decorations */}
      <div className="absolute inset-0 pointer-events-none -z-10">
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/[0.02] via-transparent to-violet-500/[0.02] rounded-3xl" />
        <div className="absolute inset-0 bg-slate-800/10 backdrop-blur-3xl rounded-3xl" />
        <motion.div 
          className="absolute top-0 left-1/2 -translate-x-1/2 w-32 h-1 bg-gradient-to-r from-indigo-500/20 via-violet-500/20 to-indigo-500/20"
          animate={{ 
            opacity: [0.3, 0.6, 0.3],
            width: ["6rem", "10rem", "6rem"]
          }}
          transition={{ duration: 3, repeat: Infinity }}
        />
      </div>

      {/* Quick Predictions */}
      <div className="relative z-10">
        <motion.h3 
          className="text-lg font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent mb-4"
          animate={{ 
            opacity: [0.8, 1, 0.8],
            scale: [1, 1.02, 1]
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          Quick Predictions
        </motion.h3>
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-3 gap-4"
          variants={containerVariants}
        >
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="Game Winner"
              prediction={predictions?.gameWinner}
              type="gameWinner"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="Total Points"
              prediction={predictions?.teamTotalPoints}
              type="totalPoints"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="First TD Scorer"
              prediction={predictions?.firstTouchdown}
              type="firstTouchdown"
            />
          </motion.div>
        </motion.div>
      </div>

      {/* Detailed Predictions */}
      <motion.div 
        className="relative z-10 space-y-4"
        variants={containerVariants}
      >
        <motion.h3 
          className="text-lg font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent"
          animate={{ 
            opacity: [0.8, 1, 0.8],
            scale: [1, 1.02, 1]
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          Detailed Predictions
        </motion.h3>
        <motion.div 
          className="grid grid-cols-1 gap-4"
          variants={containerVariants}
        >
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="QB Passing Yards"
              prediction={predictions?.qbPassingYards}
              type="qbPassingYards"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="Top TD Scorers"
              prediction={predictions?.topTouchdownScorers}
              type="topTouchdownScorers"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="First Team to Score"
              prediction={predictions?.firstToScore}
              type="firstToScore"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="First Half Winner"
              prediction={predictions?.firstHalfWinner}
              type="firstHalfWinner"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="Winning Margin"
              prediction={predictions?.winningMargin}
              type="winningMargin"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="Home Team Points"
              prediction={predictions?.teamTotalPoints?.home}
              type="teamTotalPoints"
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <PredictionCard
              label="Away Team Points"
              prediction={predictions?.teamTotalPoints?.away}
              type="teamTotalPoints"
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default NFLPredictions; 