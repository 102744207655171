import React from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import NFLGameCardPredictions from '../GameOverview/sports/NFL/NFLGameCardPredictions';
import NBAGameCardPredictions from '../GameOverview/sports/NBA/NBAGameCardPredictions';

const GamePreviewCard = ({ game }) => {
  const gameDate = new Date(game.sport === 'NBA' ? game.date : game.date.timestamp * 1000);
  const formattedDate = format(gameDate, 'MMM d, yyyy');

  const renderPredictions = () => {
    if (game.sport === 'NBA') {
      return <NBAGameCardPredictions predictions={game.predictions} />;
    }
    return <NFLGameCardPredictions predictions={game.predictions} />;
  };

  return (
    <motion.div
      className="relative bg-[#0B1120]/90 backdrop-blur-xl rounded-xl overflow-hidden border border-white/[0.08] min-w-[300px] max-w-[300px] flex flex-col"
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      {/* Ambient lighting effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
        <div className="absolute -left-[100%] top-[50%] w-[300%] h-1/2 bg-gradient-to-br from-blue-500/[0.08] via-transparent to-transparent rotate-12 blur-3xl animate-aurora" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(120,119,198,0.1),transparent)]" />
      </div>

      {/* Game Date */}
      <div className="relative px-3 py-2 border-b border-white/[0.05]">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="w-1 h-3 bg-gradient-to-b from-blue-500 to-blue-600/50 rounded-full" />
            <span className="text-[11px] font-medium tracking-wider uppercase text-gray-400/90">
              {formattedDate}
            </span>
          </div>
          <div className="text-xs font-bold px-2 py-0.5 rounded-full bg-gradient-to-r from-blue-500/20 to-purple-500/20 text-blue-400 border border-blue-500/20">
            {game.sport}
          </div>
        </div>
      </div>

      {/* Teams */}
      <div className="relative p-3">
        <div className="grid grid-cols-[1fr_auto_1fr] items-center gap-3">
          {/* Away Team */}
          <div className="flex items-center gap-2 min-w-0">
            <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-gray-800/80 to-gray-900 p-2 border border-white/[0.08]">
              <img
                src={game.teams.away.logo}
                alt={game.teams.away.name}
                className={`w-full h-full object-contain ${game.sport === 'NBA' ? 'rounded-full' : ''}`}
              />
            </div>
            <span className="text-sm font-bold text-white/95 truncate">
              {game.teams.away.name}
            </span>
          </div>

          {/* VS */}
          <div className="px-2 py-1 rounded-lg bg-white/[0.02] border border-white/[0.05] w-[30px] flex justify-center">
            <span className="text-xs font-bold text-white/70">VS</span>
          </div>

          {/* Home Team */}
          <div className="flex items-center justify-end gap-2 min-w-0">
            <span className="text-sm font-bold text-white/95 truncate text-right">
              {game.teams.home.name}
            </span>
            <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-gray-800/80 to-gray-900 p-2 border border-white/[0.08]">
              <img
                src={game.teams.home.logo}
                alt={game.teams.home.name}
                className={`w-full h-full object-contain ${game.sport === 'NBA' ? 'rounded-full' : ''}`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Predictions */}
      <div className="relative mt-auto">
        {renderPredictions()}
        
        {/* Preview Badge */}
        <div className="absolute -top-3 right-3 transform rotate-12">
          <div className="text-xs bg-gradient-to-r from-blue-500 to-purple-500 text-white px-2 py-0.5 rounded shadow-lg">
            🔒 PREVIEW
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default GamePreviewCard; 