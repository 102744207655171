// src/components/admin/PredictionAnalytics.jsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  ChartBarIcon, 
  UserGroupIcon, 
  CurrencyDollarIcon,
  ArrowTrendingUpIcon 
} from '@heroicons/react/24/outline';
import { useBacktestingAnalytics } from '../../hooks/useBacktestingAnalytics';
import { 
  calculateOverallAccuracy, 
  calculateRecentTrend,
  getBestBetType,
  calculateBetTypeAccuracy 
} from './utils/analyticsHelpers';
import StatCard from './cards/StatCard';
import PredictionChart from './charts/PredictionChart';
import BetTypePerformance from './charts/BetTypePerformance';
import PredictionTable from './tables/PredictionTable';

const PredictionAnalytics = () => {
  const [timeRange, setTimeRange] = useState('all');
  const [betType, setBetType] = useState('all');
  const { predictions, loading, error } = useBacktestingAnalytics(timeRange, betType);

  // Calculate stats whenever predictions change
  const stats = React.useMemo(() => {
    if (!predictions?.length) return null;
    
    return {
      totalPredictions: predictions.length,
      overallAccuracy: calculateOverallAccuracy(predictions),
      bestBetType: getBestBetType(predictions),
      recentTrend: calculateRecentTrend(predictions),
      playerPropsAccuracy: calculateBetTypeAccuracy(predictions, 'players')
    };
  }, [predictions]);

  if (error) {
    return (
      <motion.div 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }}
        className="p-6 bg-red-900/20 rounded-xl border border-red-500/20"
      >
        <h3 className="text-red-400 font-medium">Error Loading Analytics</h3>
        <p className="text-red-300 mt-2">{error}</p>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      {/* Time Range and Bet Type Filters */}
      <div className="flex flex-wrap gap-4 mb-6">
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="bg-gray-800 text-gray-200 rounded-lg px-4 py-2 border border-gray-700"
        >
          <option value="all">All Time</option>
          <option value="7">Last 7 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="90">Last 90 Days</option>
        </select>

        <select
          value={betType}
          onChange={(e) => setBetType(e.target.value)}
          className="bg-gray-800 text-gray-200 rounded-lg px-4 py-2 border border-gray-700"
        >
          <option value="all">All Bet Types</option>
          <option value="gameWinner">Game Winner</option>
          <option value="pointSpread">Point Spread</option>
          <option value="totalPoints">Total Points</option>
          <option value="players">Player Props</option>
        </select>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Predictions"
          value={stats?.totalPredictions || 0}
          type="number"
          icon={ChartBarIcon}
        />
        <StatCard
          title="Overall Accuracy"
          value={stats?.overallAccuracy || 0}
          change={stats?.recentTrend || 0}
          type="percentage"
          icon={ArrowTrendingUpIcon}
        />
        <StatCard
          title="Best Performing Bet"
          value={stats?.bestBetType || 'N/A'}
          type="text"
          icon={CurrencyDollarIcon}
        />
        <StatCard
          title="Player Props Accuracy"
          value={stats?.playerPropsAccuracy || 0}
          type="percentage"
          icon={UserGroupIcon}
        />
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <PredictionChart predictions={predictions || []} />
        <BetTypePerformance predictions={predictions || []} />
      </div>

      {/* Detailed Table */}
      <PredictionTable 
        predictions={predictions || []} 
        loading={loading}
        betType={betType}
      />
    </motion.div>
  );
};

export default PredictionAnalytics;