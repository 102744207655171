import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const PredictionChart = ({ predictions = [] }) => {
  // Calculate metrics for a single prediction
  const calculateMetrics = (prediction) => {
    let correct = 0;
    let total = 0;

    // Game winner
    if (prediction.results?.gameWinner) {
      total++;
      if (prediction.results.gameWinner.correct) correct++;
    }

    // Point spread
    if (prediction.results?.pointSpread) {
      total++;
      if (prediction.results.pointSpread.correct) correct++;
    }

    // Total points
    if (prediction.results?.totalPoints) {
      total++;
      if (prediction.results.totalPoints.correct) correct++;
    }

    // Player props - matching PredictionTable logic
    if (prediction.results?.playerPerformance?.predictions) {
      prediction.results.playerPerformance.predictions.forEach(player => {
        ['points', 'rebounds', 'assists'].forEach(stat => {
          if (player[stat] !== undefined) {
            total++;
            if (player.correct?.[stat]) correct++;
          }
        });
      });
    }

    return {
      accuracy: total > 0 ? (correct / total) * 100 : 0,
      total,
      correct
    };
  };

  // Calculate rolling average
  const calculateRollingAverage = (data, window = 7) => {
    return data.map((_, index) => {
      const start = Math.max(0, index - window + 1);
      const windowData = data.slice(start, index + 1);
      const sum = windowData.reduce((acc, val) => acc + val, 0);
      return sum / windowData.length;
    });
  };

  // Sort predictions by game date and prepare chart data
  const chartData = predictions
    .sort((a, b) => new Date(a.gameDate) - new Date(b.gameDate))
    .map(pred => {
      const metrics = calculateMetrics(pred);
      return {
        date: new Date(pred.gameDate),
        accuracy: metrics.accuracy,
        total: metrics.total
      };
    });

  // Calculate rolling averages
  const accuracyData = chartData.map(d => d.accuracy);
  const rollingAccuracy = calculateRollingAverage(accuracyData);
  const dailyAccuracy = accuracyData;

  const data = {
    labels: chartData.map(d => format(d.date, 'MMM d')),
    datasets: [
      {
        label: 'Rolling Accuracy (7-day)',
        data: rollingAccuracy,
        borderColor: 'rgb(99, 102, 241)',
        backgroundColor: 'rgba(99, 102, 241, 0.1)',
        fill: true,
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 0
      },
      {
        label: 'Daily Accuracy',
        data: dailyAccuracy,
        borderColor: 'rgba(99, 102, 241, 0.3)',
        borderWidth: 1,
        pointRadius: 1,
        tension: 0,
        borderDash: [2, 2]
      }
    ]
  };

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'rgb(156, 163, 175)',
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 15,
          font: {
            size: 11
          }
        }
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const date = chartData[context[0].dataIndex].date;
            return format(date, 'MMM d, yyyy');
          },
          label: (context) => {
            const value = context.raw;
            return `${context.dataset.label}: ${value.toFixed(1)}%`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        grid: {
          color: 'rgba(75, 85, 99, 0.2)'
        },
        ticks: {
          color: 'rgb(156, 163, 175)',
          callback: (value) => `${value}%`,
          font: {
            size: 11
          }
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: 'rgb(156, 163, 175)',
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 12,
          font: {
            size: 11
          }
        }
      }
    }
  };

  // Calculate current metrics
  const currentAccuracy = rollingAccuracy[rollingAccuracy.length - 1] || 0;
  const weekTrend = currentAccuracy - (rollingAccuracy[rollingAccuracy.length - 8] || currentAccuracy);

  // Calculate total accuracy
  const totalCorrect = predictions.reduce((sum, pred) => {
    const metrics = calculateMetrics(pred);
    return sum + metrics.correct;
  }, 0);
  
  const totalPredictions = predictions.reduce((sum, pred) => {
    const metrics = calculateMetrics(pred);
    return sum + metrics.total;
  }, 0);

  const overallAccuracy = totalPredictions > 0 ? (totalCorrect / totalPredictions) * 100 : 0;

  return (
    <div className="bg-gray-800/50 rounded-xl p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-gray-200">Prediction Accuracy Over Time</h3>
        <div className="flex items-center space-x-4 text-sm">
          <div className="flex items-center">
            <div className="w-2 h-2 rounded-full bg-indigo-500 mr-2"></div>
            <span className="text-gray-400">Rolling Accuracy</span>
          </div>
          <div className="flex items-center">
            <div className="w-2 h-2 rounded-full bg-indigo-300 mr-2"></div>
            <span className="text-gray-400">Daily</span>
          </div>
        </div>
      </div>
      <Line data={data} options={options} />
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="bg-gray-700/30 p-3 rounded-lg">
          <p className="text-sm text-gray-400">Overall Accuracy</p>
          <p className="text-xl font-semibold text-indigo-400">
            {overallAccuracy.toFixed(1)}%
          </p>
          <p className="text-xs text-gray-500">
            {totalCorrect} of {totalPredictions} predictions correct
          </p>
        </div>
        <div className="bg-gray-700/30 p-3 rounded-lg">
          <p className="text-sm text-gray-400">Current (7-day)</p>
          <p className="text-xl font-semibold text-indigo-400">
            {currentAccuracy.toFixed(1)}%
          </p>
        </div>
        <div className="bg-gray-700/30 p-3 rounded-lg">
          <p className="text-sm text-gray-400">7-Day Trend</p>
          <p className={`text-xl font-semibold ${weekTrend >= 0 ? 'text-green-400' : 'text-red-400'}`}>
            {weekTrend > 0 ? '+' : ''}{weekTrend.toFixed(1)}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default PredictionChart; 