import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const NBAGameStatItem = ({ stat }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!stat) return null;

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const contentVariants = {
    hidden: { 
      height: 0,
      opacity: 0
    },
    visible: { 
      height: "auto",
      opacity: 1,
      transition: {
        height: {
          type: "spring",
          stiffness: 500,
          damping: 30
        },
        opacity: {
          duration: 0.2
        }
      }
    }
  };

  return (
    <motion.div 
      className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 rounded-xl border border-white/5"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover={{ scale: 1.01 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
      
      {/* Header - Always visible */}
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="relative w-full px-4 py-3 flex items-center hover:bg-gray-700/30 transition-colors duration-200"
        whileHover={{ x: 2 }}
      >
        <div className="flex items-center w-48">
          <motion.div 
            className="relative w-6 h-6 mr-2"
            whileHover={{ rotate: [0, -5, 5, 0] }}
            transition={{ duration: 0.5 }}
          >
            <img 
              src={stat.opponent.logo} 
              alt={stat.opponent.name}
              className="w-full h-full"
            />
            <motion.div 
              className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-4 h-[1px] bg-gradient-to-r from-transparent via-blue-500/50 to-transparent"
              animate={{ 
                opacity: [0.3, 1, 0.3],
                width: ["12px", "20px", "12px"]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            />
          </motion.div>
          <span className="text-white">vs {stat.opponent.name}</span>
        </div>
        
        <div className="flex-1 flex items-center">
          <motion.span 
            className="text-gray-300 mr-8"
            animate={{ 
              opacity: [0.8, 1, 0.8],
              scale: [1, 1.02, 1]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            {stat.points?.total || 0} - {stat.score.opponent}
          </motion.span>
          <span className="text-gray-500">
            {new Date(stat.gameDate).toLocaleDateString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric'
            })}
          </span>
        </div>

        <motion.div 
          className="text-blue-400 ml-4"
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ type: "spring", stiffness: 200, damping: 20 }}
        >
          ▼
        </motion.div>
      </motion.button>

      {/* Collapsible Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            className="overflow-hidden"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <div className="relative p-4 space-y-4 border-t border-gray-700/50">
              {/* Scoring */}
              <StatSection title="Scoring">
                <div className="grid grid-cols-2 gap-2">
                  <StatBox title="Points" value={stat.points?.total || 0} />
                  <div className="bg-gray-800/50 p-3 rounded-lg">
                    <div className="text-xs text-gray-400 mb-1">Quarter Breakdown</div>
                    <div className="text-sm text-white space-x-2">
                      <span>1st: {stat.points?.quarter_1 || 0}</span>
                      <span>2nd: {stat.points?.quarter_2 || 0}</span>
                      <span>3rd: {stat.points?.quarter_3 || 0}</span>
                      <span>4th: {stat.points?.quarter_4 || 0}</span>
                      {stat.points?.over_time && <span>OT: {stat.points.over_time}</span>}
                    </div>
                  </div>
                </div>
              </StatSection>

              {/* Shooting Details */}
              <StatSection title="Shooting Details">
                <div className="grid grid-cols-3 gap-2">
                  <StatBox 
                    title="FG" 
                    value={`${stat.fieldGoals?.total || 0}/${stat.fieldGoals?.attempts || 0}`} 
                  />
                  <StatBox 
                    title="3PT" 
                    value={`${stat.threePoints?.total || 0}/${stat.threePoints?.attempts || 0}`} 
                  />
                  <StatBox 
                    title="FT" 
                    value={`${stat.freeThrows?.total || 0}/${stat.freeThrows?.attempts || 0}`} 
                  />
                </div>
              </StatSection>

              {/* Percentages */}
              <StatSection title="Shooting %">
                <div className="grid grid-cols-3 gap-2">
                  <StatBox 
                    title="FG%" 
                    value={`${stat.fieldGoals?.percentage || 0}%`} 
                  />
                  <StatBox 
                    title="3P%" 
                    value={`${stat.threePoints?.percentage || 0}%`} 
                  />
                  <StatBox 
                    title="FT%" 
                    value={`${stat.freeThrows?.percentage || 0}%`} 
                  />
                </div>
              </StatSection>

              {/* Rebounds */}
              <StatSection title="Rebounds">
                <div className="grid grid-cols-3 gap-2">
                  <StatBox 
                    title="Offensive" 
                    value={stat.rebounds?.offensive || 0} 
                  />
                  <StatBox 
                    title="Defensive" 
                    value={stat.rebounds?.defensive || 0} 
                  />
                  <StatBox 
                    title="Total" 
                    value={stat.rebounds?.total || 0} 
                  />
                </div>
              </StatSection>

              {/* Additional Stats */}
              <StatSection title="Additional Stats">
                <div className="grid grid-cols-3 gap-2">
                  <StatBox title="Assists" value={stat.assists || 0} />
                  <StatBox title="Steals" value={stat.steals || 0} />
                  <StatBox title="Blocks" value={stat.blocks || 0} />
                </div>
              </StatSection>

              {/* Other Stats */}
              <StatSection title="Other">
                <div className="grid grid-cols-2 gap-2">
                  <StatBox title="Turnovers" value={stat.turnovers || 0} />
                  <StatBox title="Personal Fouls" value={stat.personalFouls || 0} />
                </div>
              </StatSection>

              {stat.type === 'season_average' && (
                <div className="mt-2 text-xs text-gray-400">
                  Games Played: {stat.gamesPlayed || 0}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const StatSection = ({ title, children }) => (
  <div>
    <motion.h4 
      className="text-xs text-gray-400 uppercase mb-2 font-medium flex items-center space-x-2"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <span>{title}</span>
      <motion.div 
        className="w-1.5 h-1.5 rounded-full bg-blue-500"
        animate={{ 
          scale: [1, 1.5, 1],
          opacity: [0.5, 1, 0.5]
        }}
        transition={{ duration: 2, repeat: Infinity }}
      />
    </motion.h4>
    {children}
  </div>
);

const StatBox = ({ title, value }) => (
  <motion.div 
    className="bg-gray-800/50 p-3 rounded-lg"
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300, damping: 15 }}
  >
    <div className="text-xs text-gray-400 mb-1">{title}</div>
    <motion.div 
      className="text-sm font-medium bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent"
      animate={{ 
        opacity: [0.8, 1, 0.8],
        scale: [1, 1.02, 1]
      }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      {value}
    </motion.div>
  </motion.div>
);

export default NBAGameStatItem; 