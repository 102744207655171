import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createCheckoutSession } from '../../services/stripe';
import { motion } from 'framer-motion';

const SubscribeButton = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const handleSubscribe = async () => {
    setLoading(true);
    try {
      console.log('Starting checkout session creation...', user.uid);
      const result = await createCheckoutSession(user.uid);
      console.log('Checkout session result:', result);
    } catch (error) {
      console.error('Detailed subscription error:', {
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      alert('Failed to start subscription process. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      {/* Background glow effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-violet-600/20 via-indigo-600/20 to-violet-600/20 blur-xl" />
      
      {/* Button container with glass effect */}
      <motion.div
        className="relative"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <motion.button
          onClick={handleSubscribe}
          disabled={loading}
          className={`
            relative w-full px-6 py-3 rounded-xl
            bg-gradient-to-r from-indigo-600 to-violet-600
            hover:from-indigo-500 hover:to-violet-500
            text-white font-medium
            transition-all duration-200
            shadow-lg shadow-indigo-500/25
            overflow-hidden
            ${loading ? 'opacity-75 cursor-not-allowed' : ''}
          `}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30
          }}
        >
          <div className="relative z-10 flex items-center justify-center space-x-2">
            {loading ? (
              <>
                <motion.div
                  className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full"
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                />
                <span>Processing...</span>
              </>
            ) : (
              <>
                <span className="text-lg">Subscribe Now</span>
                <motion.span 
                  className="text-sm bg-white/20 px-2 py-1 rounded-full"
                  animate={{ 
                    scale: [1, 1.05, 1],
                    opacity: [0.8, 1, 0.8] 
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  $4.99/month
                </motion.span>
              </>
            )}
          </div>

          {/* Animated background gradient */}
          <motion.div
            className="absolute inset-0 -z-10"
            animate={{
              background: [
                'linear-gradient(45deg, rgba(99,102,241,0.3) 0%, rgba(124,58,237,0.3) 100%)',
                'linear-gradient(45deg, rgba(124,58,237,0.3) 0%, rgba(99,102,241,0.3) 100%)',
                'linear-gradient(45deg, rgba(99,102,241,0.3) 0%, rgba(124,58,237,0.3) 100%)'
              ]
            }}
            transition={{ duration: 3, repeat: Infinity }}
          />

          {/* Shine effect */}
          <motion.div
            className="absolute inset-0 -z-10"
            initial={{ x: '-100%' }}
            animate={{ x: '200%' }}
            transition={{ duration: 1.5, repeat: Infinity, repeatDelay: 3 }}
          >
            <div className="w-1/3 h-full bg-gradient-to-r from-transparent via-white/10 to-transparent transform rotate-45" />
          </motion.div>
        </motion.button>
      </motion.div>

      {/* Bottom glow */}
      <motion.div
        className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-3/4 h-1 bg-gradient-to-r from-transparent via-indigo-500/50 to-transparent blur-sm"
        animate={{
          opacity: [0.5, 1, 0.5],
          width: ['60%', '80%', '60%']
        }}
        transition={{ duration: 2, repeat: Infinity }}
      />
    </div>
  );
};

export default SubscribeButton;