import React, { useEffect } from 'react';
import InfoTooltip from '../../../common/InfoTooltip';
import { motion } from 'framer-motion';

const NBAGameCardPredictions = ({ predictions, predictionData }) => {
  console.log('Odds Structure:', predictionData?.odds);

  useEffect(() => {
    const fanduelImg = new Image();
    fanduelImg.src = '/images/sportsbooks/fanduel.png';
    fanduelImg.onerror = (e) => console.error('FanDuel logo failed to load:', e);

    const draftkingsImg = new Image();
    draftkingsImg.src = '/images/sportsbooks/draftkings.png';
    draftkingsImg.onerror = (e) => console.error('DraftKings logo failed to load:', e);
  }, []);

  return (
    <div className="flex flex-col gap-3 p-4 max-w-full overflow-hidden">
      {/* Game Winner */}
      <div className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 rounded-xl border border-white/[0.05]">
        {/* Ambient background effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
          <div className="absolute -left-[100%] top-[50%] w-[300%] h-1/2 bg-gradient-to-br from-blue-500/[0.08] via-transparent to-transparent rotate-12 blur-3xl animate-aurora" />
        </div>

        <div className="relative p-4 space-y-4">
          {/* Header with glowing confidence */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <motion.div 
                className="w-1 h-4 bg-gradient-to-b from-blue-500 to-blue-600/50 rounded-full"
                animate={{ 
                  height: ["16px", "14px", "16px"],
                  opacity: [1, 0.7, 1]
                }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
              <span className="text-[13px] font-medium tracking-[0.15em] uppercase text-gray-400/90 font-['Space_Grotesk']">
                Projected Winner
              </span>
              <InfoTooltip content={predictions?.gameWinner?.reasoning} />
            </div>
            <motion.div 
              className="text-lg font-bold font-['Space_Grotesk']"
              animate={{ 
                opacity: [0.8, 1, 0.8],
                textShadow: [
                  "0 0 8px rgba(59, 130, 246, 0.5)",
                  "0 0 16px rgba(59, 130, 246, 0.7)",
                  "0 0 8px rgba(59, 130, 246, 0.5)"
                ]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              <span className="bg-gradient-to-r from-blue-400 via-blue-500 to-purple-400 bg-clip-text text-transparent">
                {predictions?.gameWinner?.confidence ? 
                  `${Math.round(predictions.gameWinner.confidence)}%` 
                  : '--'}
              </span>
            </motion.div>
          </div>

          {/* Winner Display with holographic effect */}
          <motion.div 
            className="relative group"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            <div className="relative bg-white/[0.03] backdrop-blur-sm rounded-lg px-4 py-3 border border-white/10">
              <div className="flex items-center justify-between">
                <div className="text-[15px] font-bold text-white font-['Space_Grotesk'] tracking-wide">
                  {predictions?.gameWinner?.winner || 'Analyzing...'}
                </div>
                <div className="flex items-center gap-3">
                  {predictions?.gameWinner?.winner && predictionData?.odds && (
                    <>
                      {/* FanDuel */}
                      {predictionData.odds?.fanduel?.markets?.h2h?.outcomes?.[predictions.gameWinner.winner.toLowerCase()]?.link && (
                        <a 
                          href={predictionData.odds.fanduel.markets.h2h.outcomes[predictions.gameWinner.winner.toLowerCase()].link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="transition-transform hover:scale-110"
                        >
                          <img 
                            src="/images/sportsbooks/fanduel.png" 
                            alt="FanDuel"
                            className="h-5 w-auto opacity-80 hover:opacity-100"
                          />
                        </a>
                      )}

                      {/* DraftKings */}
                      {predictionData.odds?.draftkings?.markets?.h2h?.outcomes?.[predictions.gameWinner.winner.toLowerCase()]?.link && (
                        <a 
                          href={predictionData.odds.draftkings.markets.h2h.outcomes[predictions.gameWinner.winner.toLowerCase()].link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="transition-transform hover:scale-110"
                        >
                          <img 
                            src="/images/sportsbooks/draftkings.png" 
                            alt="DraftKings"
                            className="h-5 w-auto opacity-80 hover:opacity-100"
                          />
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </motion.div>

          {/* Key Factors Section */}
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <span className="text-[11px] font-medium tracking-[0.15em] uppercase text-gray-400/90 font-['Space_Grotesk']">
                Key Factors
              </span>
              <motion.div 
                className="w-1.5 h-1.5 rounded-full bg-blue-400/50"
                animate={{ 
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
            </div>

            <div className="flex flex-wrap items-center gap-2">
              {/* Confidence Factors - show if "strong" or "moderate", excluding locationAdvantage */}
              {predictions?.gameWinner?.confidenceFactors && Object.entries(predictions.gameWinner.confidenceFactors)
                .filter(([key, value]) => 
                  key !== 'locationAdvantage' && 
                  (value.toLowerCase() === 'strong' || value.toLowerCase() === 'moderate')
                )
                .map(([key, value]) => (
                  <motion.div 
                    key={key}
                    className={`px-3 py-1.5 rounded-md text-xs font-medium font-['Space_Grotesk'] tracking-wider ${
                      value.toLowerCase() === 'strong'
                        ? 'bg-green-500/20 text-green-400 border border-green-500/30'
                        : 'bg-blue-500/20 text-blue-400 border border-blue-500/30'
                    }`}
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    {key === 'efficiencyAdvantage' && '🎯'}
                    {key === 'playerAdvantage' && '👥'}
                    {key === 'recordStrength' && '📊'}
                    {' '}
                    {key.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                  </motion.div>
                ))}

              {/* Key Factors - show if "positive" or "favorable" */}
              {predictions?.gameWinner?.keyFactors && Object.entries(predictions.gameWinner.keyFactors)
                .filter(([_, value]) => value.toLowerCase() === 'positive' || value.toLowerCase() === 'favorable')
                .map(([key, value]) => (
                  <motion.div 
                    key={key}
                    className="px-3 py-1.5 rounded-md text-xs font-medium font-['Space_Grotesk'] tracking-wider bg-violet-500/20 text-violet-400 border border-violet-500/30"
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    {key === 'momentum' && '📈'}
                    {key === 'defensiveEdge' && '🛡️'}
                    {key === 'offensiveEdge' && '⚔️'}
                    {key === 'playerMatchups' && '🤼'}
                    {key === 'homeCourtAdvantage' && '🏟️'}
                    {' '}
                    {key.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                  </motion.div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* Total Points */}
      <div className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 rounded-xl border border-white/[0.05]">
        {/* Ambient background effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/[0.02] via-transparent to-blue-500/[0.02] animate-pulse" />
          <div className="absolute -left-[100%] top-[50%] w-[300%] h-1/2 bg-gradient-to-br from-purple-500/[0.08] via-transparent to-transparent rotate-12 blur-3xl animate-aurora" />
        </div>

        <div className="relative p-4 space-y-4">
          {/* Header with glowing confidence */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <motion.div 
                className="w-1 h-4 bg-gradient-to-b from-purple-500 to-blue-600/50 rounded-full"
                animate={{ 
                  height: ["16px", "14px", "16px"],
                  opacity: [1, 0.7, 1]
                }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
              <span className="text-[13px] font-medium tracking-[0.15em] uppercase text-gray-400/90 font-['Space_Grotesk']">
                Total Points
              </span>
              <InfoTooltip content={predictions?.totalPoints?.reasoning} />
            </div>
            <motion.div 
              className="text-lg font-bold font-['Space_Grotesk']"
              animate={{ 
                opacity: [0.8, 1, 0.8],
                textShadow: [
                  "0 0 8px rgba(147, 51, 234, 0.5)",
                  "0 0 16px rgba(147, 51, 234, 0.7)",
                  "0 0 8px rgba(147, 51, 234, 0.5)"
                ]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              <span className="bg-gradient-to-r from-purple-400 via-purple-500 to-blue-400 bg-clip-text text-transparent">
                {predictions?.totalPoints?.confidence ? 
                  `${Math.round(predictions.totalPoints.confidence)}%` 
                  : '--'}
              </span>
            </motion.div>
          </div>

          {/* Points Display with debug info */}
          <motion.div 
            className="relative group"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 via-blue-500/10 to-purple-500/10 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            <div className="relative bg-white/[0.03] backdrop-blur-sm rounded-lg px-4 py-3 border border-white/10">
              <div className="flex items-center justify-between">
                <div className="text-[15px] font-bold text-white font-['Space_Grotesk'] tracking-wide">
                  {predictions?.totalPoints?.total || 'Analyzing...'}
                </div>
                <div className="flex items-center gap-3">
                  {predictions?.totalPoints?.overUnderRecommendation && predictionData?.odds && (
                    <>
                      {/* FanDuel */}
                      {predictionData.odds?.fanduel?.markets?.totals?.outcomes?.[predictions.totalPoints.overUnderRecommendation.toLowerCase()]?.link && (
                        <a 
                          href={predictionData.odds.fanduel.markets.totals.outcomes[predictions.totalPoints.overUnderRecommendation.toLowerCase()].link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="transition-transform hover:scale-110"
                        >
                          <img 
                            src="/images/sportsbooks/fanduel.png" 
                            alt="FanDuel"
                            className="h-5 w-auto opacity-80 hover:opacity-100"
                          />
                        </a>
                      )}

                      {/* DraftKings */}
                      {predictionData.odds?.draftkings?.markets?.totals?.outcomes?.[predictions.totalPoints.overUnderRecommendation.toLowerCase()]?.link && (
                        <a 
                          href={predictionData.odds.draftkings.markets.totals.outcomes[predictions.totalPoints.overUnderRecommendation.toLowerCase()].link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="transition-transform hover:scale-110"
                        >
                          <img 
                            src="/images/sportsbooks/draftkings.png" 
                            alt="DraftKings"
                            className="h-5 w-auto opacity-80 hover:opacity-100"
                          />
                        </a>
                      )}
                    </>
                  )}
                  
                  <span className={`text-sm font-medium px-2 py-0.5 rounded ${
                    predictions?.totalPoints?.overUnderRecommendation === 'OVER' 
                      ? 'bg-green-500/20 text-green-400 border border-green-500/30' 
                      : 'bg-red-500/20 text-red-400 border border-red-500/30'
                  }`}>
                    {predictions?.totalPoints?.overUnderRecommendation || ''}
                  </span>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Key Factors */}
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <span className="text-[11px] font-medium tracking-[0.15em] uppercase text-gray-400/90 font-['Space_Grotesk']">
                Key Factors
              </span>
              <motion.div 
                className="w-1.5 h-1.5 rounded-full bg-purple-400/50"
                animate={{ 
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
            </div>

            <div className="flex flex-wrap gap-2">
              {predictions?.totalPoints?.keyFactors?.defensiveStrength && (
                <motion.div 
                  className="px-3 py-1.5 rounded-md text-xs font-medium font-['Space_Grotesk'] tracking-wider bg-red-500/20 text-red-400 border border-red-500/30"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  🛡️ DEFENSE: {predictions.totalPoints.keyFactors.defensiveStrength.toUpperCase()}
                </motion.div>
              )}
              {predictions?.totalPoints?.keyFactors?.homeCourtImpact && (
                <motion.div 
                  className="px-3 py-1.5 rounded-md text-xs font-medium font-['Space_Grotesk'] tracking-wider bg-green-500/20 text-green-400 border border-green-500/30"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  🏟️ HOME COURT: {predictions.totalPoints.keyFactors.homeCourtImpact.toUpperCase()}
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MVP Performance */}
      {predictions?.mvpPerformance && (
        <div className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-4 hover:bg-white/[0.04] transition-colors duration-200">
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="text-[13px] text-gray-400">MVP Performance</span>
                <InfoTooltip content={predictions.mvpPerformance.reasoning} />
              </div>
              <motion.div 
                className="text-lg font-bold text-blue-400 ml-2"
                animate={{ 
                  opacity: [0.8, 1, 0.8]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                {Math.round(predictions.mvpPerformance.confidence)}%
              </motion.div>
            </div>
            <div className="bg-white/[0.01] rounded-lg p-3 hover:bg-white/[0.03] transition-colors duration-200">
              <div className="text-[15px] font-medium text-white truncate">
                {predictions.mvpPerformance.player}
              </div>
              <div className="text-[13px] text-gray-400 truncate">
                {predictions.mvpPerformance.prediction}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NBAGameCardPredictions;
