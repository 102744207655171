import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { useBacktestingAnalytics } from '../../hooks/useBacktestingAnalytics';
import { calculateBetTypeAccuracy } from './utils/analyticsHelpers';
import StatCard from './cards/StatCard';
import { ChartBarIcon, TrophyIcon, ArrowTrendingUpIcon, ScaleIcon } from '@heroicons/react/24/outline';
import BetTypePerformance from './charts/BetTypePerformance';

const BetTypeAnalytics = () => {
  const { predictions, loading } = useBacktestingAnalytics();

  const stats = useMemo(() => {
    if (!predictions?.length) return null;

    const betTypes = ['gameWinner', 'pointSpread', 'totalPoints', 'players'];
    const accuracies = betTypes.map(type => ({
      type,
      accuracy: calculateBetTypeAccuracy(predictions, type)
    }));

    const bestType = accuracies.reduce((a, b) => a.accuracy > b.accuracy ? a : b);
    const worstType = accuracies.reduce((a, b) => a.accuracy < b.accuracy ? a : b);
    const avgAccuracy = accuracies.reduce((sum, curr) => sum + curr.accuracy, 0) / accuracies.length;

    return {
      bestPerforming: {
        type: bestType.type,
        accuracy: bestType.accuracy
      },
      worstPerforming: {
        type: worstType.type,
        accuracy: worstType.accuracy
      },
      averageAccuracy: avgAccuracy,
      totalPredictions: predictions.length
    };
  }, [predictions]);

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6"
      >
        <div className="animate-pulse space-y-6">
          {/* Loading skeleton */}
          <div className="h-8 bg-gray-700 rounded w-1/4"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="h-32 bg-gray-700 rounded"></div>
            ))}
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      <h2 className="text-xl font-semibold text-white">Bet Type Analytics</h2>
      
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Best Performing Type"
          value={stats?.bestPerforming.type.replace(/([A-Z])/g, ' $1').trim()}
          type="text"
          icon={TrophyIcon}
        />
        <StatCard
          title="Best Type Accuracy"
          value={stats?.bestPerforming.accuracy || 0}
          type="percentage"
          icon={ChartBarIcon}
        />
        <StatCard
          title="Average Accuracy"
          value={stats?.averageAccuracy || 0}
          type="percentage"
          icon={ArrowTrendingUpIcon}
        />
        <StatCard
          title="Accuracy Range"
          value={`${(stats?.worstPerforming.accuracy || 0).toFixed(1)}% - ${(stats?.bestPerforming.accuracy || 0).toFixed(1)}%`}
          type="text"
          icon={ScaleIcon}
        />
      </div>

      {/* Performance Chart */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <BetTypePerformance predictions={predictions || []} />
        
        {/* Additional charts or analysis can be added here */}
        <div className="bg-gray-800/50 rounded-xl p-6">
          <h3 className="text-lg font-medium text-gray-200 mb-4">Detailed Analysis</h3>
          {/* Add detailed analysis components */}
        </div>
      </div>

      {/* Detailed Stats Table */}
      <div className="bg-gray-800/50 rounded-xl overflow-hidden">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-900/50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Bet Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Accuracy
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Total Predictions
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Success Rate
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {['gameWinner', 'pointSpread', 'totalPoints', 'players'].map((type) => (
              <tr key={type} className="hover:bg-gray-700/30">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {type.replace(/([A-Z])/g, ' $1').trim()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {`${calculateBetTypeAccuracy(predictions || [], type).toFixed(1)}%`}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {predictions?.filter(p => p.results[type]).length || 0}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {`${((predictions?.filter(p => p.results[type]?.correct).length || 0) / 
                     (predictions?.filter(p => p.results[type]).length || 1) * 100).toFixed(1)}%`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

export default BetTypeAnalytics; 