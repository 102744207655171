import React, { useState } from 'react';
import { useGameDetails } from '../../../../hooks/useGameDetails';
import { motion, AnimatePresence } from 'framer-motion';

const NFLPlayerGameItem = ({ gameId, players }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { gameDetails, loading } = useGameDetails(gameId);
  const [selectedGroup, setSelectedGroup] = useState('all');

  const statGroups = ['all', 'Defensive', 'Rushing', 'Receiving', 'Fumbles', 'Punt_returns'];

  if (!players || !gameDetails) return null;
  if (gameDetails.stage === 'Pre Season') return null;

  const availableGroups = new Set();
  Object.values(players).forEach(player => {
    if (player.statGroups) {
      Object.keys(player.statGroups).forEach(group => availableGroups.add(group));
    }
  });

  const stageOrder = {
    'Post Season': 3,
    'Regular Season': 2
  };

  const sortedGames = Object.entries(players)
    .sort((a, b) => {
      const stageComparison = (stageOrder[gameDetails.stage] || 0);
      
      if (stageComparison === 0) {
        const weekA = parseInt(gameDetails.week?.replace('Week ', '') || '0');
        const weekB = parseInt(gameDetails.week?.replace('Week ', '') || '0');
        return weekB - weekA;
      }
      
      return stageComparison;
    });

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const contentVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { 
      opacity: 1,
      height: "auto",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <motion.div 
      className="bg-slate-800/30 rounded-xl overflow-hidden border border-white/[0.05]"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      layout
    >
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-4 hover:bg-slate-700/30"
        whileHover={{ x: 2 }}
        transition={{ type: "spring", stiffness: 400, damping: 25 }}
      >
        <div className="flex items-center space-x-4">
          {gameDetails && (
            <motion.div 
              className="flex items-center space-x-3"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <div className="flex items-center space-x-2">
                <motion.div 
                  className="w-8 h-8 bg-slate-800/50 rounded-lg p-1 border border-white/[0.08]"
                  whileHover={{ scale: 1.05, rotate: [-1, 1, -1] }}
                >
                  <img src={gameDetails.teams.home.logo} alt={gameDetails.teams.home.name} className="w-full h-full object-contain" />
                </motion.div>
                <span className="text-sm text-slate-400">vs</span>
                <motion.div 
                  className="w-8 h-8 bg-slate-800/50 rounded-lg p-1 border border-white/[0.08]"
                  whileHover={{ scale: 1.05, rotate: [-1, 1, -1] }}
                >
                  <img src={gameDetails.teams.away.logo} alt={gameDetails.teams.away.name} className="w-full h-full object-contain" />
                </motion.div>
              </div>
              <motion.span 
                className="text-sm text-slate-300 bg-slate-800/50 px-3 py-1 rounded-full border border-white/[0.08]"
                whileHover={{ scale: 1.05 }}
              >
                {gameDetails.week}
              </motion.span>
              {gameDetails.stage === 'Post Season' && (
                <motion.span 
                  className="text-xs text-indigo-400 bg-indigo-400/10 px-3 py-1 rounded-full border border-indigo-400/20"
                  whileHover={{ scale: 1.05 }}
                  animate={{ 
                    opacity: [0.8, 1, 0.8],
                    scale: [1, 1.02, 1]
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  Playoffs
                </motion.span>
              )}
            </motion.div>
          )}
          {loading && (
            <motion.div 
              className="flex items-center space-x-2"
              animate={{ opacity: [0.5, 1, 0.5] }}
              transition={{ duration: 1.5, repeat: Infinity }}
            >
              <div className="w-2 h-2 rounded-full bg-indigo-500" />
              <span className="text-sm text-slate-400">Loading game details...</span>
            </motion.div>
          )}
        </div>
        <motion.span 
          className="text-slate-400 transform transition-transform duration-200"
          animate={{ rotate: isExpanded ? 180 : 0 }}
        >
          ▼
        </motion.span>
      </motion.button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            className="p-4"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <motion.div 
              className="flex space-x-2 overflow-x-auto pb-2 mb-3 scrollbar-thin scrollbar-thumb-slate-700 scrollbar-track-transparent"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              {Array.from(availableGroups).map((group, index) => (
                <motion.button
                  key={group}
                  onClick={() => setSelectedGroup(group)}
                  className={`px-4 py-1.5 rounded-full text-sm whitespace-nowrap border ${
                    selectedGroup === group
                      ? 'bg-indigo-500 text-white border-indigo-400'
                      : 'bg-slate-800/50 text-slate-400 border-white/[0.05] hover:bg-slate-700/50'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  {group.charAt(0).toUpperCase() + group.slice(1).replace('_', ' ')}
                </motion.button>
              ))}
            </motion.div>

            <motion.div 
              className="space-y-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <AnimatePresence mode="popLayout">
                {sortedGames
                  .filter(([_, player]) => 
                    selectedGroup === 'all' || 
                    player.statGroups?.[selectedGroup]
                  )
                  .map(([playerId, player], index) => (
                    <motion.div
                      key={playerId}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <NFLPlayerStatsCard 
                        player={player}
                        selectedGroup={selectedGroup}
                      />
                    </motion.div>
                  ))}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const NFLPlayerStatsCard = ({ player, selectedGroup }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const contentVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { 
      opacity: 1,
      height: "auto",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };
  
  return (
    <motion.div 
      className="bg-slate-800/50 rounded-xl overflow-hidden border border-white/[0.05]"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      layout
    >
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-3 hover:bg-slate-700/30"
        whileHover={{ x: 2 }}
        transition={{ type: "spring", stiffness: 400, damping: 25 }}
      >
        <div className="flex items-center space-x-3">
          <motion.div 
            className="w-10 h-10 bg-slate-800/50 rounded-lg p-1 border border-white/[0.08] overflow-hidden"
            whileHover={{ scale: 1.05 }}
          >
            <img src={player.player.image} alt="" className="w-full h-full object-cover rounded" />
          </motion.div>
          <motion.span 
            className="text-slate-200 font-medium"
            animate={{ 
              opacity: [0.8, 1, 0.8]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            {player.player.name}
          </motion.span>
        </div>
        <motion.span 
          className="text-slate-400 transform transition-transform duration-200"
          animate={{ rotate: isExpanded ? 180 : 0 }}
        >
          ▼
        </motion.span>
      </motion.button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            className="p-3 grid gap-2"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {Object.entries(player.statGroups)
              .filter(([group]) => selectedGroup === 'all' || group === selectedGroup)
              .map(([group, stats], groupIndex) => (
                <motion.div 
                  key={group} 
                  className="bg-slate-900/50 rounded-xl p-3 border border-white/[0.05]"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: groupIndex * 0.1 }}
                >
                  <motion.div 
                    className="text-xs font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent mb-2"
                    animate={{ 
                      opacity: [0.8, 1, 0.8],
                      scale: [1, 1.02, 1]
                    }}
                    transition={{ duration: 2, repeat: Infinity }}
                  >
                    {group.replace('_', ' ')}
                  </motion.div>
                  <div className="grid grid-cols-2 gap-2">
                    {stats.map((stat, index) => (
                      <motion.div 
                        key={index} 
                        className="text-sm bg-slate-800/30 rounded-lg p-2 border border-white/[0.03]"
                        whileHover={{ scale: 1.02, x: 2 }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.05 }}
                      >
                        <motion.span 
                          className="text-slate-400"
                          animate={{ 
                            opacity: [0.7, 1, 0.7]
                          }}
                          transition={{ duration: 2, repeat: Infinity }}
                        >
                          {stat.name}:
                        </motion.span>
                        <motion.span 
                          className="ml-1 font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent"
                          animate={{ 
                            opacity: [0.8, 1, 0.8]
                          }}
                          transition={{ duration: 2, repeat: Infinity }}
                        >
                          {stat.value || '0'}
                        </motion.span>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default NFLPlayerGameItem; 