import React, { useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useBacktestingAnalytics } from '../../hooks/useBacktestingAnalytics';
import {
  ChartBarIcon,
  ArrowTrendingUpIcon,
  FireIcon,
  ChartPieIcon,
} from '@heroicons/react/24/outline';

const PlayerAnalytics = () => {
  const [timeRange, setTimeRange] = useState('all');
  const { predictions, loading } = useBacktestingAnalytics(timeRange);
  const [selectedStat, setSelectedStat] = useState('all');

  const playerStats = useMemo(() => {
    if (!predictions?.length) return { players: [], topPerformers: [], statBreakdown: {} };

    const playerMap = new Map();

    // Process each prediction
    predictions.forEach(prediction => {
      if (!prediction.results?.players) return;

      // Process each player's results
      Object.entries(prediction.results.players).forEach(([playerName, playerResults]) => {
        if (!playerMap.has(playerName)) {
          playerMap.set(playerName, {
            name: playerName,
            stats: {
              points: { correct: 0, total: 0 },
              rebounds: { correct: 0, total: 0 },
              assists: { correct: 0, total: 0 }
            },
            totalCorrect: 0,
            totalPredictions: 0,
            confidence: {
              points: [],
              rebounds: [],
              assists: []
            }
          });
        }

        const playerData = playerMap.get(playerName);

        // Process each stat type
        Object.entries(playerResults.correct || {}).forEach(([stat, isCorrect]) => {
          if (['points', 'rebounds', 'assists'].includes(stat)) {
            playerData.stats[stat].total++;
            playerData.totalPredictions++;
            if (isCorrect) {
              playerData.stats[stat].correct++;
              playerData.totalCorrect++;
            }
            // Store confidence values for averaging later
            const confidence = prediction.confidence?.players?.[stat] || 0;
            playerData.confidence[stat].push(confidence);
          }
        });
      });
    });

    // Convert to array and calculate percentages
    const players = Array.from(playerMap.values())
      .map(player => ({
        ...player,
        accuracy: (player.totalCorrect / player.totalPredictions) * 100,
        stats: Object.entries(player.stats).reduce((acc, [stat, data]) => ({
          ...acc,
          [stat]: {
            ...data,
            accuracy: data.total > 0 ? (data.correct / data.total) * 100 : 0,
            avgConfidence: player.confidence[stat].length > 0 
              ? (player.confidence[stat].reduce((sum, val) => sum + val, 0) / player.confidence[stat].length) * 100
              : 0
          }
        }), {})
      }))
      .filter(player => player.totalPredictions >= 5); // Only include players with at least 5 predictions

    // Get top performers (overall and by stat)
    const topPerformers = {
      overall: [...players]
        .sort((a, b) => b.accuracy - a.accuracy)
        .slice(0, 5),
      points: [...players]
        .filter(p => p.stats.points.total >= 3)
        .sort((a, b) => b.stats.points.accuracy - a.stats.points.accuracy)
        .slice(0, 5),
      rebounds: [...players]
        .filter(p => p.stats.rebounds.total >= 3)
        .sort((a, b) => b.stats.rebounds.accuracy - a.stats.rebounds.accuracy)
        .slice(0, 5),
      assists: [...players]
        .filter(p => p.stats.assists.total >= 3)
        .sort((a, b) => b.stats.assists.accuracy - a.stats.assists.accuracy)
        .slice(0, 5)
    };

    // Calculate stat breakdown
    const statBreakdown = {
      points: players.reduce((acc, p) => ({
        correct: acc.correct + p.stats.points.correct,
        total: acc.total + p.stats.points.total
      }), { correct: 0, total: 0 }),
      rebounds: players.reduce((acc, p) => ({
        correct: acc.correct + p.stats.rebounds.correct,
        total: acc.total + p.stats.rebounds.total
      }), { correct: 0, total: 0 }),
      assists: players.reduce((acc, p) => ({
        correct: acc.correct + p.stats.assists.correct,
        total: acc.total + p.stats.assists.total
      }), { correct: 0, total: 0 })
    };

    return { players, topPerformers, statBreakdown };
  }, [predictions]);

  if (loading) {
    return (
      <div className="animate-pulse space-y-6">
        <div className="h-8 bg-gray-700 rounded w-1/4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="h-32 bg-gray-700 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  const statTypes = [
    { id: 'all', name: 'Overall' },
    { id: 'points', name: 'Points' },
    { id: 'rebounds', name: 'Rebounds' },
    { id: 'assists', name: 'Assists' }
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      {/* Filters */}
      <div className="flex flex-wrap gap-4">
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="bg-gray-800 text-gray-200 rounded-lg px-4 py-2 border border-gray-700"
        >
          <option value="all">All Time</option>
          <option value="7">Last 7 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="90">Last 90 Days</option>
        </select>

        <div className="flex gap-2">
          {statTypes.map((stat) => (
            <button
              key={stat.id}
              onClick={() => setSelectedStat(stat.id)}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                selectedStat === stat.id
                  ? 'bg-indigo-500/10 text-indigo-400'
                  : 'text-gray-400 hover:text-white hover:bg-white/5'
              }`}
            >
              {stat.name}
            </button>
          ))}
        </div>
      </div>

      {/* Summary Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Players Tracked"
          value={playerStats.players.length}
          icon={ChartBarIcon}
          color="blue"
        />
        <StatCard
          title="Overall Success Rate"
          value={`${((playerStats.players.reduce((sum, p) => sum + p.totalCorrect, 0) / 
                     playerStats.players.reduce((sum, p) => sum + p.totalPredictions, 0)) * 100).toFixed(1)}%`}
          icon={ArrowTrendingUpIcon}
          color="green"
        />
        <StatCard
          title="Most Accurate Player"
          value={playerStats.topPerformers.overall[0]?.name || 'N/A'}
          subtitle={`${playerStats.topPerformers.overall[0]?.accuracy.toFixed(1)}% Success`}
          icon={FireIcon}
          color="orange"
        />
        <StatCard
          title="Most Predicted Stat"
          value={Object.entries(playerStats.statBreakdown)
            .sort((a, b) => b[1].total - a[1].total)[0]?.[0]
            .charAt(0).toUpperCase() + 
            Object.entries(playerStats.statBreakdown)
            .sort((a, b) => b[1].total - a[1].total)[0]?.[0]
            .slice(1) || 'N/A'}
          subtitle={`${Object.entries(playerStats.statBreakdown)
            .sort((a, b) => b[1].total - a[1].total)[0]?.[1].total || 0} predictions`}
          icon={ChartPieIcon}
          color="purple"
        />
      </div>

      {/* Top Performers */}
      <div className="bg-gray-800/50 rounded-xl p-6">
        <h3 className="text-lg font-medium text-gray-200 mb-4">
          Top Performers {selectedStat !== 'all' ? `- ${selectedStat.charAt(0).toUpperCase() + selectedStat.slice(1)}` : ''}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {(selectedStat === 'all' ? playerStats.topPerformers.overall : playerStats.topPerformers[selectedStat])
            .map((player, index) => (
              <motion.div
                key={player.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-700/30 rounded-lg p-4"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="text-white font-medium">{player.name}</div>
                  <div className="text-indigo-400 font-bold">
                    {selectedStat === 'all' 
                      ? `${player.accuracy.toFixed(1)}%`
                      : `${player.stats[selectedStat].accuracy.toFixed(1)}%`}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2 text-sm">
                  <div>
                    <div className="text-gray-400">Points</div>
                    <div className="text-gray-200">{player.stats.points.accuracy.toFixed(1)}%</div>
                    <div className="text-xs text-gray-500">
                      ({player.stats.points.correct}/{player.stats.points.total})
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Rebounds</div>
                    <div className="text-gray-200">{player.stats.rebounds.accuracy.toFixed(1)}%</div>
                    <div className="text-xs text-gray-500">
                      ({player.stats.rebounds.correct}/{player.stats.rebounds.total})
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Assists</div>
                    <div className="text-gray-200">{player.stats.assists.accuracy.toFixed(1)}%</div>
                    <div className="text-xs text-gray-500">
                      ({player.stats.assists.correct}/{player.stats.assists.total})
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
        </div>
      </div>

      {/* Detailed Stats Table */}
      <div className="bg-gray-800/50 rounded-xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-gray-900/50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Player
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Overall
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Points
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Rebounds
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Assists
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {playerStats.players
                .sort((a, b) => b.accuracy - a.accuracy)
                .map((player) => (
                  <tr key={player.name} className="hover:bg-gray-700/30">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {player.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center">
                        <div className={`w-2 h-2 rounded-full mr-2 ${
                          player.accuracy >= 70 ? 'bg-green-500' :
                          player.accuracy >= 50 ? 'bg-yellow-500' :
                          'bg-red-500'
                        }`}></div>
                        <span className="text-gray-300">{player.accuracy.toFixed(1)}%</span>
                        <span className="text-gray-500 text-xs ml-2">
                          ({player.totalCorrect}/{player.totalPredictions})
                        </span>
                      </div>
                    </td>
                    {['points', 'rebounds', 'assists'].map(stat => (
                      <td key={stat} className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {player.stats[stat].total > 0 ? (
                          <div className="flex flex-col">
                            <div className="flex items-center">
                              <div className={`w-2 h-2 rounded-full mr-2 ${
                                player.stats[stat].accuracy >= 70 ? 'bg-green-500' :
                                player.stats[stat].accuracy >= 50 ? 'bg-yellow-500' :
                                'bg-red-500'
                              }`}></div>
                              <span>{player.stats[stat].accuracy.toFixed(1)}%</span>
                              <span className="text-gray-500 text-xs ml-2">
                                ({player.stats[stat].correct}/{player.stats[stat].total})
                              </span>
                            </div>
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </motion.div>
  );
};

const StatCard = ({ title, value, subtitle, icon: Icon, color }) => {
  const colorClasses = {
    blue: 'text-blue-400',
    green: 'text-green-400',
    orange: 'text-orange-400',
    purple: 'text-purple-400'
  };

  return (
    <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/50">
      <div className="flex items-center justify-between">
        <div className="w-12 h-12 rounded-lg bg-gray-700/50 flex items-center justify-center">
          <Icon className={`w-6 h-6 ${colorClasses[color]}`} />
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-gray-400 text-sm">{title}</h3>
        <p className={`text-2xl font-semibold mt-1 ${colorClasses[color]}`}>{value}</p>
        {subtitle && (
          <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
        )}
      </div>
    </div>
  );
};

export default PlayerAnalytics; 