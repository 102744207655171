import React, { useState } from 'react';
import { useGameDetails } from '../../../../hooks/useGameDetails';
import { calculateSeasonAverages } from '../../../../utils/statsAverages';
import SeasonAverageItem from '../../SeasonAverageItem';
import { motion, AnimatePresence } from 'framer-motion';

const NFLGameStatItem = ({ stats }) => {
  if (Array.isArray(stats)) {
    const nonPreseasonStats = stats.filter(stat => !stat.game?.stage === 'Pre Season');
    const sortedStats = nonPreseasonStats.sort((a, b) => {
      const stageOrder = {
        'Post Season': 3,
        'Regular Season': 2
      };
      
      const stageComparison = (stageOrder[b.game?.stage] || 0) - (stageOrder[a.game?.stage] || 0);
      
      if (stageComparison === 0) {
        const weekA = parseInt(a.game?.week?.replace('Week ', '') || '0');
        const weekB = parseInt(b.game?.week?.replace('Week ', '') || '0');
        return weekB - weekA;
      }
      
      return stageComparison;
    });

    const seasonAverages = calculateSeasonAverages(sortedStats);
    
    return (
      <motion.div 
        className="space-y-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <SeasonAverageItem averages={seasonAverages} />
        <AnimatePresence mode="popLayout">
          {sortedStats.map((gameStat, index) => (
            <motion.div
              key={gameStat.gameId}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ delay: index * 0.1 }}
            >
              <IndividualGameStat stat={gameStat} />
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    );
  }

  if (stats.game?.stage === 'Pre Season') return null;
  return <IndividualGameStat stat={stats} />;
};

const IndividualGameStat = ({ stat }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { gameDetails, loading } = useGameDetails(stat?.gameId);

  if (!stat || !stat.statistics) return null;

  const {
    statistics: {
      yards = {},
      points_against = {},
      passing = {},
      rushings = {},
      first_downs = {},
      posession = {},
    }
  } = stat;

  const statCardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const contentVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { 
      opacity: 1,
      height: "auto",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <motion.div 
      className="bg-slate-800/30 rounded-xl overflow-hidden border border-white/[0.05]"
      variants={statCardVariants}
      initial="hidden"
      animate="visible"
      layout
    >
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-4 hover:bg-slate-700/30"
        whileHover={{ x: 2 }}
        transition={{ type: "spring", stiffness: 400, damping: 25 }}
      >
        <div className="flex items-center space-x-4">
          {gameDetails && (
            <motion.div 
              className="flex items-center space-x-3"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <div className="flex items-center space-x-2">
                <motion.div 
                  className="w-8 h-8 bg-slate-800/50 rounded-lg p-1 border border-white/[0.08]"
                  whileHover={{ scale: 1.05, rotate: [-1, 1, -1] }}
                >
                  <img 
                    src={gameDetails.teams.home.logo} 
                    alt={gameDetails.teams.home.name}
                    className="w-full h-full object-contain"
                  />
                </motion.div>
                <span className="text-sm text-slate-400">vs</span>
                <motion.div 
                  className="w-8 h-8 bg-slate-800/50 rounded-lg p-1 border border-white/[0.08]"
                  whileHover={{ scale: 1.05, rotate: [-1, 1, -1] }}
                >
                  <img 
                    src={gameDetails.teams.away.logo} 
                    alt={gameDetails.teams.away.name}
                    className="w-full h-full object-contain"
                  />
                </motion.div>
              </div>
              <motion.span 
                className="text-sm text-slate-300 bg-slate-800/50 px-3 py-1 rounded-full border border-white/[0.08]"
                whileHover={{ scale: 1.05 }}
              >
                {gameDetails.week}
              </motion.span>
            </motion.div>
          )}
          {loading && (
            <motion.div 
              className="flex items-center space-x-2"
              animate={{ opacity: [0.5, 1, 0.5] }}
              transition={{ duration: 1.5, repeat: Infinity }}
            >
              <div className="w-2 h-2 rounded-full bg-indigo-500" />
              <span className="text-sm text-slate-400">Loading game details...</span>
            </motion.div>
          )}
        </div>
        <motion.span 
          className="text-slate-400 transform transition-transform duration-200"
          animate={{ rotate: isExpanded ? 180 : 0 }}
        >
          ▼
        </motion.span>
      </motion.button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            className="p-4 space-y-4"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {/* Offensive Stats */}
            <div>
              <motion.h4 
                className="text-xs font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent uppercase mb-2"
                animate={{ 
                  opacity: [0.8, 1, 0.8],
                  scale: [1, 1.02, 1]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                Offense
              </motion.h4>
              <div className="grid grid-cols-2 gap-2">
                <StatBox label="Total Yards" value={yards?.total || 0} />
                <StatBox label="Points" value={points_against?.total || 0} />
              </div>
            </div>

            {/* Passing Stats */}
            <div>
              <motion.h4 
                className="text-xs font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent uppercase mb-2"
                animate={{ 
                  opacity: [0.8, 1, 0.8],
                  scale: [1, 1.02, 1]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                Passing
              </motion.h4>
              <StatBox label="Comp/Att" value={passing?.comp_att || '0-0'} />
              <StatBox label="Yards" value={passing?.total || 0} />
            </div>

            {/* Rushing Stats */}
            <div>
              <motion.h4 
                className="text-xs font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent uppercase mb-2"
                animate={{ 
                  opacity: [0.8, 1, 0.8],
                  scale: [1, 1.02, 1]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                Rushing
              </motion.h4>
              <div className="grid grid-cols-2 gap-2">
                <StatBox label="Attempts" value={rushings?.attempts || 0} />
                <StatBox label="Yards" value={rushings?.total || 0} />
              </div>
            </div>

            {/* Additional Stats */}
            <div>
              <motion.h4 
                className="text-xs font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent uppercase mb-2"
                animate={{ 
                  opacity: [0.8, 1, 0.8],
                  scale: [1, 1.02, 1]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                Additional Stats
              </motion.h4>
              <div className="grid grid-cols-2 gap-2">
                <StatBox label="First Downs" value={first_downs?.total || 0} />
                <StatBox label="Possession" value={posession?.total || '00:00'} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const StatBox = ({ label, value }) => (
  <motion.div 
    className="bg-slate-800/50 p-3 rounded-xl border border-white/[0.05]"
    whileHover={{ scale: 1.02, x: 2 }}
    transition={{ type: "spring", stiffness: 400, damping: 25 }}
  >
    <motion.div 
      className="text-xs text-slate-400 mb-1"
      animate={{ 
        opacity: [0.7, 1, 0.7]
      }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      {label}
    </motion.div>
    <motion.div 
      className="text-sm font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent"
      animate={{ 
        opacity: [0.8, 1, 0.8],
        scale: [1, 1.02, 1]
      }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      {value}
    </motion.div>
  </motion.div>
);

export default NFLGameStatItem; 