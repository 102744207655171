import React from 'react';
import { useNBAPlayerSeasonAverages } from '../../../../hooks/sports/useNBAPlayerSeasonAverages';
import { motion, AnimatePresence } from 'framer-motion';

const NBAPlayerStats = ({ teamId }) => {
  const { playerAverages, loading, error } = useNBAPlayerSeasonAverages(teamId);

  if (loading) {
    return (
      <motion.div 
        className="flex flex-col items-center justify-center py-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div 
          className="w-12 h-12 rounded-full border-4 border-indigo-500/20 border-t-indigo-500"
          animate={{ 
            rotate: 360,
          }}
          transition={{ 
            duration: 1,
            repeat: Infinity,
            ease: "linear"
          }}
        />
        <motion.div 
          className="text-slate-400 mt-4"
          animate={{ 
            opacity: [0.5, 1, 0.5],
            scale: [0.98, 1, 0.98]
          }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          Loading player stats...
        </motion.div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div 
        className="text-center py-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <motion.div 
          className="text-rose-400 text-lg mb-2"
          animate={{ 
            scale: [1, 1.05, 1],
            opacity: [0.8, 1, 0.8]
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          ⚠️
        </motion.div>
        <div className="text-rose-400">Error: {error}</div>
      </motion.div>
    );
  }

  // Sort players by impact (minutes * games played)
  const sortedPlayers = [...playerAverages].sort((a, b) => {
    const impactScoreA = (a.minutes || 0) * (a.gamesPlayed || 0);
    const impactScoreB = (b.minutes || 0) * (b.gamesPlayed || 0);
    return impactScoreB - impactScoreA;
  });

  return (
    <motion.div 
      className="space-y-3 max-h-[400px] overflow-y-auto pr-2 custom-scrollbar"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AnimatePresence>
        {sortedPlayers.map((player, index) => (
          <PlayerContainer 
            key={player.playerId} 
            player={player} 
            index={index}
          />
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

const PlayerContainer = ({ player, index }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const calculatePercentage = (makes, attempts) => {
    if (!attempts || attempts === 0) return 0;
    return Math.round((makes / attempts) * 100);
  };

  const fieldGoalPercentage = calculatePercentage(player.fieldGoals?.total, player.fieldGoals?.attempts);
  const threePointPercentage = calculatePercentage(player.threePoints?.total, player.threePoints?.attempts);
  const freeThrowPercentage = calculatePercentage(player.freeThrows?.total, player.freeThrows?.attempts);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        delay: index * 0.1
      }
    }
  };

  const contentVariants = {
    hidden: { 
      height: 0,
      opacity: 0
    },
    visible: { 
      height: "auto",
      opacity: 1,
      transition: {
        height: {
          type: "spring",
          stiffness: 500,
          damping: 30
        },
        opacity: {
          duration: 0.2
        }
      }
    }
  };

  return (
    <motion.div 
      className="relative overflow-hidden bg-gradient-to-br from-slate-800/50 via-slate-800/30 to-slate-800/50 rounded-xl border border-white/[0.08]"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover={{ scale: 1.01 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/[0.03] via-transparent to-violet-500/[0.03] animate-pulse" />
      
      {/* Header - Always visible */}
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="relative w-full px-4 py-3 flex items-center hover:bg-slate-700/20 transition-colors duration-200"
        whileHover={{ x: 2 }}
      >
        <div className="flex items-center flex-1">
          <motion.div 
            className="text-slate-100 font-medium"
            animate={{ 
              opacity: [0.8, 1, 0.8],
              scale: [1, 1.02, 1]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            {player.playerName}
          </motion.div>
          <div className="text-slate-400 text-sm ml-3 flex items-center space-x-3">
            <span>{player.gamesPlayed} Games</span>
            <motion.div 
              className="w-1.5 h-1.5 rounded-full bg-indigo-500"
              animate={{ 
                scale: [1, 1.5, 1],
                opacity: [0.5, 1, 0.5]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            />
            <motion.span
              animate={{ 
                opacity: [0.6, 0.8, 0.6]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              {Math.round(player.minutes)} MPG
            </motion.span>
          </div>
        </div>

        <motion.div 
          className="text-indigo-400 ml-4"
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ type: "spring", stiffness: 200, damping: 20 }}
        >
          ▼
        </motion.div>
      </motion.button>

      {/* Collapsible Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            className="overflow-hidden"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <div className="relative p-4 border-t border-white/[0.06]">
              <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                <StatGroup title="Scoring">
                  <StatBox title="Points" value={player.points?.toFixed(1)} />
                  <StatBox title="FG%" value={`${fieldGoalPercentage}%`} />
                  <StatBox title="3P%" value={`${threePointPercentage}%`} />
                </StatGroup>
                
                <StatGroup title="Other">
                  <StatBox title="Rebounds" value={player.rebounds?.total?.toFixed(1)} />
                  <StatBox title="Assists" value={player.assists?.toFixed(1)} />
                  <StatBox title="FT%" value={`${freeThrowPercentage}%`} />
                </StatGroup>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const StatGroup = ({ title, children }) => (
  <div>
    <motion.h4 
      className="text-xs text-slate-400 uppercase mb-2 font-medium flex items-center space-x-2"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <span>{title}</span>
      <motion.div 
        className="w-1.5 h-1.5 rounded-full bg-indigo-500"
        animate={{ 
          scale: [1, 1.5, 1],
          opacity: [0.5, 1, 0.5]
        }}
        transition={{ duration: 2, repeat: Infinity }}
      />
    </motion.h4>
    <div className="space-y-2">
      {children}
    </div>
  </div>
);

const StatBox = ({ title, value }) => (
  <motion.div 
    className="bg-slate-800/30 p-2 rounded-lg border border-white/[0.03]"
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300, damping: 15 }}
  >
    <div className="text-xs text-slate-400 mb-1">{title}</div>
    <motion.div 
      className="text-sm font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent"
      animate={{ 
        opacity: [0.8, 1, 0.8],
        scale: [1, 1.02, 1]
      }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      {value || '0'}
    </motion.div>
  </motion.div>
);

export default NBAPlayerStats; 