import React from 'react';
import InfoTooltip from '../../../common/InfoTooltip';
import { motion } from 'framer-motion';

const NFLGameCardPredictions = ({ predictions }) => {
  const getTotalPoints = (predictions) => {
    if (!predictions?.teamTotalPoints?.home?.predictedPoints || 
        !predictions?.teamTotalPoints?.away?.predictedPoints) {
      return '--';
    }
    return predictions.teamTotalPoints.home.predictedPoints + 
           predictions.teamTotalPoints.away.predictedPoints;
  };

  return (
    <div className="flex flex-col gap-3 p-4 max-w-full overflow-hidden">
      {/* Game Winner Card */}
      <div className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 rounded-xl border border-white/[0.05]">
        {/* Ambient background effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
          <div className="absolute -left-[100%] top-[50%] w-[300%] h-1/2 bg-gradient-to-br from-blue-500/[0.08] via-transparent to-transparent rotate-12 blur-3xl animate-aurora" />
        </div>

        <div className="relative p-4 space-y-4">
          {/* Header with confidence */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <motion.div 
                className="w-1 h-4 bg-gradient-to-b from-blue-500 to-blue-600/50 rounded-full"
                animate={{ 
                  height: ["16px", "14px", "16px"],
                  opacity: [1, 0.7, 1]
                }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
              <span className="text-[13px] font-medium tracking-[0.15em] uppercase text-gray-400/90">
                Projected Winner
              </span>
              <InfoTooltip content={predictions?.gameWinner?.reasoning} />
            </div>
            <motion.div 
              className="text-lg font-bold"
              animate={{ 
                opacity: [0.8, 1, 0.8],
                textShadow: [
                  "0 0 8px rgba(59, 130, 246, 0.5)",
                  "0 0 16px rgba(59, 130, 246, 0.7)",
                  "0 0 8px rgba(59, 130, 246, 0.5)"
                ]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              <span className="bg-gradient-to-r from-blue-400 via-blue-500 to-purple-400 bg-clip-text text-transparent">
                {predictions?.gameWinner?.confidence}%
              </span>
            </motion.div>
          </div>

          {/* Winner Display with margin */}
          <motion.div 
            className="relative group"
            whileHover={{ scale: 1.02 }}
          >
            <div className="relative bg-white/[0.03] backdrop-blur-sm rounded-lg px-4 py-3 border border-white/10">
              <div className="flex items-center justify-between">
                <div className="text-[15px] font-bold text-white">
                  {predictions?.gameWinner?.winner || 'Analyzing...'}
                </div>
                {predictions?.winningMargin?.margin && (
                  <span className="text-sm text-blue-400 font-medium">
                    Spread: - {predictions.winningMargin.margin} pts
                  </span>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Total Points Card - Matched styling with Winner Card */}
      <div className="relative overflow-hidden bg-gradient-to-br from-gray-800/50 via-gray-800/30 to-gray-800/50 rounded-xl border border-white/[0.05]">
        {/* Ambient background effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02] animate-pulse" />
          <div className="absolute -left-[100%] top-[50%] w-[300%] h-1/2 bg-gradient-to-br from-blue-500/[0.08] via-transparent to-transparent rotate-12 blur-3xl animate-aurora" />
        </div>

        <div className="relative p-4 space-y-4">
          {/* Header with confidence */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <motion.div 
                className="w-1 h-4 bg-gradient-to-b from-blue-500 to-blue-600/50 rounded-full"
                animate={{ 
                  height: ["16px", "14px", "16px"],
                  opacity: [1, 0.7, 1]
                }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
              <span className="text-[13px] font-medium tracking-[0.15em] uppercase text-gray-400/90">
                Total Points
              </span>
              <InfoTooltip 
                content={`${predictions?.teamTotalPoints?.home?.reasoning}\n\n${predictions?.teamTotalPoints?.away?.reasoning}`}
              />
            </div>
            <motion.div 
              className="text-lg font-bold"
              animate={{ 
                opacity: [0.8, 1, 0.8],
                textShadow: [
                  "0 0 8px rgba(59, 130, 246, 0.5)",
                  "0 0 16px rgba(59, 130, 246, 0.7)",
                  "0 0 8px rgba(59, 130, 246, 0.5)"
                ]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              <span className="bg-gradient-to-r from-blue-400 via-blue-500 to-purple-400 bg-clip-text text-transparent">
                {predictions?.teamTotalPoints?.home?.confidence && predictions?.teamTotalPoints?.away?.confidence ? 
                  `${Math.round(((predictions.teamTotalPoints.home.confidence + predictions.teamTotalPoints.away.confidence) / 2) * 100)}%` 
                  : '--'}
              </span>
            </motion.div>
          </div>
          
          {/* Points Display */}
          <motion.div 
            className="relative group"
            whileHover={{ scale: 1.02 }}
          >
            <div className="relative bg-white/[0.03] backdrop-blur-sm rounded-lg px-4 py-3 border border-white/10">
              <div className="flex items-center justify-between">
                <div className="text-[15px] font-bold text-white">
                  {getTotalPoints(predictions) !== '--' ? `${getTotalPoints(predictions)} points` : '--'}
                </div>
                <span className="text-sm text-blue-400 font-medium">
                  {predictions?.teamTotalPoints?.home?.predictedPoints || '--'} - {predictions?.teamTotalPoints?.away?.predictedPoints || '--'}
                </span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default NFLGameCardPredictions; 