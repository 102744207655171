import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BetTypePerformance = ({ predictions }) => {
  const calculateMetrics = (type) => {
    if (!predictions?.length) return { accuracy: 0, confidence: 0, volume: 0 };

    if (type === 'players') {
      let totalProps = 0;
      let correctProps = 0;
      let totalConfidence = 0;
      
      predictions.forEach(pred => {
        if (pred.results.players) {
          Object.values(pred.results.players).forEach(player => {
            Object.entries(player.correct || {}).forEach(([stat, isCorrect]) => {
              totalProps++;
              if (isCorrect) correctProps++;
              const confidence = pred.confidence?.players?.[stat] || 0;
              totalConfidence += confidence;
            });
          });
        }
      });
      
      const accuracy = totalProps > 0 ? (correctProps / totalProps) * 100 : 0;
      const avgConfidence = totalProps > 0 ? (totalConfidence / totalProps) * 100 : 0;
      
      return {
        accuracy,
        predictedWinRate: avgConfidence,
        volume: totalProps,
        successRate: accuracy / (avgConfidence || 1)
      };
    }

    const relevantPreds = predictions.filter(p => p.results[type]);
    if (!relevantPreds.length) return { accuracy: 0, predictedWinRate: 0, volume: 0, successRate: 0 };
    
    const correct = relevantPreds.filter(p => p.results[type].correct).length;
    const totalConfidence = relevantPreds.reduce((sum, p) => sum + (p.confidence[type] * 100), 0);
    const accuracy = (correct / relevantPreds.length) * 100;
    const avgConfidence = totalConfidence / relevantPreds.length;
    
    return {
      accuracy,
      predictedWinRate: avgConfidence,
      volume: relevantPreds.length,
      successRate: accuracy / (avgConfidence || 1)
    };
  };

  const renderValue = (value, suffix = '%') => {
    if (value === undefined || value === null) return `0${suffix}`;
    return typeof value === 'number' ? `${value.toFixed(1)}${suffix}` : `0${suffix}`;
  };

  const chartData = [
    { name: 'Game Winner', ...calculateMetrics('gameWinner') },
    { name: 'Point Spread', ...calculateMetrics('pointSpread') },
    { name: 'Total Points', ...calculateMetrics('totalPoints') },
    { name: 'Player Props', ...calculateMetrics('players') }
  ].sort((a, b) => (b.accuracy || 0) - (a.accuracy || 0));

  if (!predictions?.length) {
    return (
      <div className="bg-gray-800/50 rounded-xl p-6">
        <h3 className="text-lg font-medium text-gray-200 mb-4">Bet Type Performance</h3>
        <div className="text-gray-400 text-center py-8">No prediction data available</div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800/50 rounded-xl p-6">
      <h3 className="text-lg font-medium text-gray-200 mb-4">Bet Type Performance</h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis 
              dataKey="name" 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
            />
            <YAxis 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
              domain={[0, 100]}
              unit="%"
            />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: '#1F2937',
                border: '1px solid #374151',
                borderRadius: '0.5rem',
                color: '#9CA3AF'
              }}
              formatter={(value, name) => {
                if (!value && value !== 0) return ['N/A', name];
                return [`${Number(value || 0).toFixed(1)}%`, 'Success Rate'];
              }}
            />
            <Legend />
            <Bar 
              dataKey="accuracy" 
              fill="#60A5FA" 
              name="Success Rate"
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {chartData.map((item) => (
          <div key={item.name} className="bg-gray-700/30 p-3 rounded-lg">
            <h4 className="text-sm font-medium text-gray-300">{item.name}</h4>
            <div className="mt-2 grid grid-cols-2 gap-4 text-xs">
              <div>
                <p className="text-gray-400">Success Rate</p>
                <p className="text-blue-400 font-medium">{renderValue(item.accuracy)}</p>
                <p className="text-xs text-gray-500">of predictions were correct</p>
              </div>
              <div>
                <p className="text-gray-400">Total Predictions</p>
                <p className="text-pink-400 font-medium">{item.volume || 0}</p>
                <p className="text-xs text-gray-500">bets analyzed</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BetTypePerformance; 