import React from 'react';
import { useNBATeamStats } from '../../../../hooks/sports/useNBATeamStats';
import NBAGameStatItem from './NBAGameStatItem';
import { motion, AnimatePresence } from 'framer-motion';

const NBAGameStats = ({ teamId }) => {
  const { teamStats, loading, error } = useNBATeamStats(teamId);

  if (loading) {
    return (
      <motion.div 
        className="flex flex-col items-center justify-center py-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div 
          className="w-12 h-12 rounded-full border-4 border-blue-500/30 border-t-blue-500"
          animate={{ 
            rotate: 360,
          }}
          transition={{ 
            duration: 1,
            repeat: Infinity,
            ease: "linear"
          }}
        />
        <motion.div 
          className="text-gray-400 mt-4"
          animate={{ 
            opacity: [0.5, 1, 0.5],
            scale: [0.98, 1, 0.98]
          }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          Loading stats...
        </motion.div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div 
        className="text-center py-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <motion.div 
          className="text-red-400 text-lg mb-2"
          animate={{ 
            scale: [1, 1.05, 1],
            opacity: [0.8, 1, 0.8]
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          ⚠️
        </motion.div>
        <div className="text-red-400">Error loading stats: {error}</div>
      </motion.div>
    );
  }

  if (!teamStats || teamStats.length === 0) {
    return (
      <motion.div 
        className="text-center py-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <motion.div 
          className="text-gray-400 text-lg mb-2"
          animate={{ 
            scale: [1, 1.05, 1],
            opacity: [0.8, 1, 0.8]
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          📊
        </motion.div>
        <div className="text-gray-400">No stats available</div>
      </motion.div>
    );
  }

  // Sort stats by date (most recent first)
  const sortedStats = [...teamStats].sort((a, b) => 
    new Date(b.gameDate) - new Date(a.gameDate)
  );

  return (
    <motion.div 
      className="space-y-3 max-h-[400px] overflow-y-auto pr-2 custom-scrollbar"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AnimatePresence>
        {sortedStats.map((stat, index) => (
          <motion.div
            key={stat.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ 
              type: "spring",
              stiffness: 300,
              damping: 30,
              delay: index * 0.1
            }}
          >
            <NBAGameStatItem stat={stat} />
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export default NBAGameStats; 