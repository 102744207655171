import React, { useState, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import { firestoreFunctions } from './firestoreFunctions';
import { marked } from 'marked';
import NFLChat from './NFLChat';
import NBAChat from './NBAChat';
import { useSport } from '../../contexts/SportContext';
import { motion, AnimatePresence } from 'framer-motion';

const functionDefinitions = [
  {
    name: "getPredictionData",
    description: "Get detailed prediction data for a specific game including team stats, key players, and matchup context",
    parameters: {
      type: "object",
      properties: {
        gameId: {
          type: "string",
          description: "The game ID to get prediction data for"
        }
      },
      required: ["gameId"]
    }
  }
];

// Add these conversation starters
const conversationStarters = {
  NFL: {
    gameAnalysis: [
      "What are the key matchup advantages for either team?",
      "Can you analyze the offensive strategies for both teams?",
      "What are the defensive strengths and weaknesses of each team?",
      "How do the quarterbacks compare statistically?",
      "What's the injury status for key players in this game?",
    ],
    statistics: [
      "Show me the season statistics for both teams",
      "Compare the rushing yards for both teams",
      "What are the passing statistics for both quarterbacks?",
      "How do these teams rank in their divisions?",
      "What are the turnover statistics for both teams?",
    ],
    predictions: [
      "Based on recent performance, which team has the advantage?",
      "What are the key factors that could decide this game?",
      "How have these teams performed against each other historically?",
      "Which team has the stronger offense?",
      "Which team has the better defense?",
    ]
  },
  NBA: {
    gameAnalysis: [
      "What are the key matchups to watch in this game?",
      "How do these teams' playing styles compare?",
      "What are the strengths and weaknesses of each team?",
      "How important is home court advantage in this matchup?",
      "What's the injury report for this game?",
    ],
    statistics: [
      "Compare the teams' shooting percentages",
      "How do their benches compare statistically?",
      "What are the rebounding numbers for both teams?",
      "Compare their defensive ratings",
      "How do they match up in points per game?",
    ],
    predictions: [
      "Which team has momentum coming into this game?",
      "What are the key factors for tonight's game?",
      "How have recent matchups between these teams gone?",
      "Which team has the scoring advantage?",
      "Who has the defensive edge?",
    ]
  }
};

// Move SuggestionBubbles to its own component file
const SuggestionBubbles = ({ onSelect, sport }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const sportStarters = conversationStarters[sport] || {};
  
  const bubbleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: { 
      opacity: 0, 
      y: -20,
      transition: {
        duration: 0.2
      }
    }
  };

  const categoryVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { 
      opacity: 1,
      height: "auto",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };
  
  if (!isExpanded) {
    return (
      <motion.div 
        className="flex items-center gap-2 text-sm"
        variants={bubbleVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <span className="text-slate-300 font-medium">Conversation Starters</span>
        <motion.button
          onClick={() => setIsExpanded(true)}
          className="text-indigo-400 hover:text-indigo-300 flex items-center gap-1 bg-slate-800/50 px-3 py-1.5 rounded-full border border-white/[0.05]"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <span>Click for suggestions</span>
          <motion.svg 
            className="w-4 h-4" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
            animate={{ rotate: [0, 10, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </motion.svg>
        </motion.button>
      </motion.div>
    );
  }

  // If no valid sport starters found, show an error message
  if (!sport || !conversationStarters[sport]) {
    return (
      <motion.div 
        className="mb-4 p-4 bg-red-500/10 rounded-xl border border-red-500/20"
        variants={bubbleVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-center justify-between">
          <span className="text-red-400 text-sm">Unable to load conversation starters</span>
          <motion.button
            onClick={() => setIsExpanded(false)}
            className="text-red-400 hover:text-red-300 bg-red-500/10 p-1.5 rounded-full"
            whileHover={{ scale: 1.1, rotate: 90 }}
            whileTap={{ scale: 0.9 }}
          >
            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </motion.button>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div 
      className="mb-4 space-y-3"
      variants={bubbleVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="flex items-center justify-between">
        <span className="text-slate-300 text-sm font-medium">Conversation Starters</span>
        <motion.button
          onClick={() => setIsExpanded(false)}
          className="text-slate-400 hover:text-white bg-slate-800/50 p-1.5 rounded-full"
          whileHover={{ scale: 1.1, rotate: 90 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </motion.button>
      </div>
      
      <motion.div 
        className="grid grid-cols-1 gap-2 bg-slate-800/30 rounded-xl p-3 border border-white/[0.05]"
        variants={categoryVariants}
      >
        {Object.entries(sportStarters).map(([category, questions]) => (
          <motion.div 
            key={category}
            className="space-y-2"
            variants={categoryVariants}
          >
            <motion.div 
              className="text-slate-300 font-medium cursor-pointer hover:bg-slate-700/50 rounded px-2 py-1.5 flex items-center justify-between"
              onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
              whileHover={{ x: 2 }}
            >
              <span>{category.replace(/([A-Z])/g, ' $1').trim()}</span>
              <motion.span
                animate={{ rotate: selectedCategory === category ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                ▼
              </motion.span>
            </motion.div>
            
            <AnimatePresence>
              {selectedCategory === category && (
                <motion.div 
                  className="pl-2 space-y-1"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {questions.map((question, index) => (
                    <motion.div
                      key={index}
                      className="text-sm text-slate-400 hover:text-white hover:bg-slate-700/50 rounded px-2 py-1.5 cursor-pointer flex items-center gap-2"
                      onClick={() => {
                        onSelect(question);
                        setIsExpanded(false);
                      }}
                      whileHover={{ x: 4, backgroundColor: "rgba(255,255,255,0.05)" }}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.05 }}
                    >
                      <motion.span
                        className="w-1.5 h-1.5 rounded-full bg-indigo-500"
                        animate={{ 
                          scale: [1, 1.2, 1],
                          opacity: [0.5, 1, 0.5]
                        }}
                        transition={{ duration: 2, repeat: Infinity, delay: index * 0.1 }}
                      />
                      {question}
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

const ChatWindow = ({ game, onClose }) => {
  const { activeSport } = useSport();
  
  console.log('Active Sport:', activeSport); // Debug log
  
  console.log('Game data received:', game);

  if (!game || !activeSport) {
    return (
      <motion.div 
        className="fixed inset-0 bg-slate-900/90 backdrop-blur-sm flex items-center justify-center z-50 p-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div 
          className="bg-red-500/10 backdrop-blur-sm rounded-lg p-4 border border-red-500/20 text-red-400 max-w-md w-full"
          initial={{ scale: 0.9, y: 20 }}
          animate={{ scale: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <div className="flex items-center gap-3 mb-2">
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <span className="font-medium">Error Loading Chat</span>
          </div>
          <p className="text-sm opacity-90">
            Unable to load chat: Missing game or sport information
          </p>
        </motion.div>
      </motion.div>
    );
  }

  const sharedProps = {
    game,
    onClose,
    SuggestionBubbles: (props) => (
      <SuggestionBubbles 
        {...props} 
        sport={activeSport}
      />
    ),
    conversationStarters: conversationStarters[activeSport]
  };

  return (
    <AnimatePresence mode="wait">
      {activeSport === 'NBA' ? (
        <NBAChat key="nba" {...sharedProps} />
      ) : activeSport === 'NFL' ? (
        <NFLChat key="nfl" {...sharedProps} />
      ) : (
        <motion.div 
          className="fixed inset-0 bg-slate-900/90 backdrop-blur-sm flex items-center justify-center z-50 p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div 
            className="bg-red-500/10 backdrop-blur-sm rounded-lg p-4 border border-red-500/20 text-red-400 max-w-md w-full"
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div className="flex items-center gap-3 mb-2">
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span className="font-medium">Unsupported Sport</span>
            </div>
            <p className="text-sm opacity-90">
              Unsupported sport type: {activeSport}
            </p>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ChatWindow;