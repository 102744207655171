// src/components/admin/cards/StatCard.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/solid';

const StatCard = ({ title, value, change, type, icon: Icon }) => {
  const isPositive = change > 0;
  
  const formatValue = () => {
    switch (type) {
      case 'percentage':
        return `${value.toFixed(1)}%`;
      case 'number':
        return value.toLocaleString();
      case 'text':
        return value;
      default:
        return value;
    }
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/50"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-indigo-500/10 rounded-lg">
            <Icon className="h-6 w-6 text-indigo-400" />
          </div>
          <h3 className="text-sm font-medium text-gray-400">{title}</h3>
        </div>
        {typeof change !== 'undefined' && (
          <span className={`text-sm ${isPositive ? 'text-green-400' : 'text-red-400'} flex items-center`}>
            {isPositive ? <ArrowUpIcon className="h-4 w-4 mr-1" /> : <ArrowDownIcon className="h-4 w-4 mr-1" />}
            {Math.abs(change).toFixed(1)}%
          </span>
        )}
      </div>
      <div className="mt-4">
        <h4 className="text-2xl font-semibold text-white">
          {formatValue()}
        </h4>
      </div>
    </motion.div>
  );
};

export default StatCard;